$color-wing: dodgerblue;
$color-sub-wing: #ffffff8a;
$color-background: #d6c18b;
$color-twitter: #4099FF;

.Butterfly_butterfly {
	animation: Butterfly_hover 500ms cubic-bezier(.48, .01, .54, 1) infinite;
	animation-direction: alternate;
	animation-fill-mode: reverse;
	position: relative;
	transform-style: preserve-3d;
	transform: rotateX(50deg) rotateY(20deg) rotateZ(-50deg) translateY(50px);
	width: 30px;

	&::before {
		background: darken($color-background, 55%);
		border-radius: 50%;
		content: '';
		display: block;
		height: 110px;
		left: 50%;
		margin-left: -10px;
		outline: 1px solid transparent;
		position: absolute;
		top: -15px;
		transform: rotatey(100deg);
		width: 20px;
		z-index: 2;
	}
}

.Butterfly_shadow {
	animation: Butterfly_shadow 500ms cubic-bezier(.48, .01, .54, 1) infinite;
	animation-direction: alternate;
	animation-fill-mode: reverse;
	background: #000;
	border-radius: 50%;
	display: block;
	height: 10px;
	opacity: 0.1;
	transform-origin: 50% 50%;
	transform: translateX(-40px) translateY(100px);
	width: 100px;

}

.Butterfly_wing {
	background: #888;
	display: block;
	opacity: 0.7;
	outline: 1px solid transparent;
	position: absolute;
	top: 0;

	&:first-child {
		animation: Butterfly_leftflap 250ms cubic-bezier(.48, .01, .54, 1) infinite;
		animation-direction: alternate;
		animation-fill-mode: reverse;
		height: 1px;
		left: 0;
		transform: rotateY(-20deg);
		transform-origin: 700% 50%;
		width: 1px;
		z-index: 3;
	}

	&:last-child {
		animation: Butterfly_rightflap 250ms cubic-bezier(.48, .01, .54, 1) infinite;
		animation-direction: alternate;
		animation-fill-mode: reverse;
		right: 0;
		transform: rotateY(200deg);
		z-index: 1;
	}

	.Butterfly_bit {
		background: dodgerblue;
	}

	.Butterfly_bit::after {
		background: $color-sub-wing;
	}

	.Butterfly_bit,
	.Butterfly_bit::after {
		border-radius: 50%;
		overflow: hidden;
		position: absolute;
		right: 0;
		top: 0;
		transform-origin: 100% 50%;
	}

	.Butterfly_bit:first-child {
		height: 70px;
		text-align: center;
		top: 15px;
		transform: rotateZ(40deg);
		width: 130px;

		&::after {
			content: '';
			display: inline-block;
			height: 60px;
			left: -30px;
			top: 5px;
			width: 100px;
		}
	}

	.Butterfly_bit:last-child {
		height: 55px;
		transform: rotateZ(-40deg);
		width: 100px;

		&::after {
			content: '';
			display: inline-block;
			height: 45px;
			left: -24px;
			top: 5px;
			width: 60px;
			z-index: 1;
		}
	}
}

@keyframes Butterfly_hover {
	0% {
		transform: rotateX(50deg) rotateY(20deg) rotateZ(-50deg) translateZ(40px) translateY(0px) translateX(-50px);
	}

	100% {
		transform: rotateX(50deg) rotateY(20deg) rotateZ(-50deg) translateZ(-10px) translateY(0px) translateX(-50px);
	}
}

@keyframes Butterfly_shadow {
	0% {
		transform: translateX(0px) translateY(90px) scale(1, 1);
	}

	100% {
		transform: translateX(0px) translateY(90px) scale(0.7, 0.7);
	}
}

@keyframes Butterfly_leftflap {
	0% {
		transform: rotateY(-20deg);
	}

	100% {
		transform: rotateY(90deg);
	}
}

@keyframes Butterfly_rightflap {
	0% {
		transform: rotateY(200deg);
	}

	100% {
		transform: rotateY(90deg);
	}
}