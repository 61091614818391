.Fruit-avocado {
    position: relative;
    width: 3em;
    height: 4.2em;
    margin: 0 auto;
    background: #cde06d;
    border-radius: 50% 50% 50% 50% / 75% 75% 40% 40%;
    border: 0.6em solid #9fcd65;
    box-shadow: 0 0 0 2px #3c632b;
}

.Fruit-avocado-inner {
    position: absolute;
    bottom: 8%;
    right: 25%;
    background: #98602d;
    border: 0.2em solid #704f31;
    width: 55%;
    height: 48%;
    border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
}

.Fruit-avocado-inner:after {
    content: "";
    height: 80%;
    width: 80%;
    opacity: 0.7;
    background: #ffffff;
    position: absolute;
    left: 13%;
    top: 14%;
    transform: rotate(8deg);
    border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
}

.Fruit-avocado-inner:before {
    content: "";
    position: absolute;
    width: 80%;
    height: 95%;
    left: 6%;
    background: #98602d;
    transform: rotate(20deg);
    border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
    z-index: 1;
}