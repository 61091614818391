.is-playing {
    animation: move-background 3000s infinite;
    background: #0078E0 url("./bg.png") bottom repeat-x;
    background-size: contain;
}

@keyframes move-background {
    to {
        background-position-x: 999999px;
    }

    from {
        background-position-x: 0px;
    }
}