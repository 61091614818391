@-webkit-keyframes Bubble-Bubble-Move {
    0% {
        transform: translateX(10px);
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
    }

    50% {
        transform: translateX(-10px);
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
    }

    100% {
        transform: translateX(10px);
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
    }
}

@keyframes Bubble-Bubble-Move {
    0% {
        transform: translateX(10px);
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
    }

    50% {
        transform: translateX(-10px);
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
    }

    100% {
        transform: translateX(10px);
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
    }
}

.Bubble-Bubble {
    position: relative;
    box-shadow: 0px 0px 12px 5px inset #fff;
    -webkit-box-shadow: 0px 0px 12px 5px inset #fff;
    -moz-box-shadow: 0px 0px 12px 5px inset #fff;
    -o-box-shadow: 0px 0px 12px 5px inset #fff;
    -ms-box-shadow: 0px 0px 12px 5px inset #fff;
    border-radius: 50%;
    display: inline-block;
    width: 100px;
    height: 100px;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
    font-size: 25px;
    -webkit-animation: Bubble-Bubble-Move 2s ease 0s infinite normal;
    animation: Bubble-Bubble-Move 2s ease 0s infinite normal;
}

.Bubble-Bubble:after {
    content: '';
    position: absolute;
    background-color: #ffffffe8;
    width: 33px;
    height: 28px;
    top: 10%;
    right: 21%;
    border-radius: 50%;
    transform: rotateZ(30deg) scaleY(0.7);
    -webkit-transform: rotateZ(30deg) scaleY(0.7);
    -moz-transform: rotateZ(30deg) scaleY(0.7);
    -ms-transform: rotateZ(30deg) scaleY(0.7);
    -o-transform: rotateZ(30deg) scaleY(0.7);
    filter: blur(1px);
    -webkit-filter: blur(1px);
}