.Fruit-Pear {
    width: 5.4em;
    height: 5.4em;
    margin-top: 1.2em;
    background-color: #d7c322;
    border-radius: 50% 15% 50% 50%;
    transform: rotate(-45deg);
    box-shadow: inset 0.15em 0.25em #c3b11f;
}

.Fruit-Pear::after {
    content: "";
    width: 0.2em;
    height: 1em;
    background-color: brown;
    position: absolute;
    z-index: 2;
    top: -0.2em;
    left: 5.1em;
    transform: rotate(45deg);
}