.spaceships {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 165px);
  width: 330px;
  height: 200px;
  -webkit-animation: spaceships 8s infinite alternate;
          animation: spaceships 8s infinite alternate;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}
.spaceships:hover .spaceships__head {
  -webkit-transform: rotate(68deg);
          transform: rotate(68deg);
  transition: 0.5s;
  left: 45px;
}
.spaceships__head {
  position: absolute;
  top: 10px;
  left: 50px;
  width: 215px;
  height: 131px;
  overflow: hidden;
  z-index: 10;
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  -webkit-transform: rotate(0);
          transform: rotate(0);
  transition: 0.5s;
}
.spaceships__head:before {
  width: 215px;
  height: 181px;
  border-radius: 50%;
  background-color: #3f4952;
  position: absolute;
  content: "";
}
.spaceships__body {
  position: absolute;
  top: 80px;
  left: 0;
  width: 320px;
  height: 115px;
  overflow: hidden;
  border-radius: 50%;
  z-index: 5;
  background-color: #0e7972;
}
.spaceships__body:before {
  position: absolute;
  content: "";
  left: -12px;
  width: 320px;
  height: 115px;
  border-radius: 50%;
  background-color: #13a79d;
  top: -8px;
}
.spaceships__body:after {
  position: absolute;
  content: "";
  top: 10px;
  left: 56px;
  width: 203px;
  height: 50px;
  border-radius: 50% 50% 20% 20%;
  background-color: #3f4952;
}
.spaceships__light {
  width: 0;
  height: 0;
  border-bottom: 20px solid #f4ca5a;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
}
.spaceships__foot {
  position: absolute;
  top: 165px;
  left: 120px;
  width: 70px;
  height: 50px;
  border-radius: 50%;
  background-color: #f4ca5a;
  z-index: 1;
}
.spaceships__foot:before {
  left: -73px;
  top: -10px;
  width: 70px;
  height: 50px;
  -webkit-transform: rotateZ(10deg);
          transform: rotateZ(10deg);
  border-radius: 50%;
  background-color: #f4ca5a;
  position: absolute;
  content: "";
}
.spaceships__foot:after {
  left: 73px;
  top: -10px;
  width: 70px;
  height: 50px;
  -webkit-transform: rotateZ(-10deg);
          transform: rotateZ(-10deg);
  border-radius: 50%;
  background-color: #f4ca5a;
  position: absolute;
  content: "";
}

.alien {
  position: absolute;
  width: 110px;
  height: 120px;
  top: 48px;
  z-index: 9;
  left: 108px;
  transition: 0.2s;
}
.alien__body {
  width: 110px;
  height: 93px;
  border-radius: 50% 50% 0 0;
  position: relative;
  background-color: #e86e7b;
}
.alien__eye {
  position: absolute;
  top: 15px;
  left: 23px;
  width: 63px;
  height: 62px;
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
}
.alien__eye:before {
  position: absolute;
  content: "";
  top: 6px;
  left: 21px;
  width: 35px;
  height: 35px;
  background-color: #000;
  border-radius: 50%;
}
.alien__eye:after {
  position: absolute;
  content: "";
  top: 0;
  left: -8px;
  width: 80px;
  height: 62px;
  background-color: #e86e7b;
  border-radius: 50%;
  -webkit-animation: eye 3s infinite alternate;
          animation: eye 3s infinite alternate;
}
.alien__ear {
  position: absolute;
  top: -15px;
  left: 37px;
  width: 10px;
  height: 30px;
  -webkit-transform: rotateZ(-28deg);
  transform: rotateZ(-28deg);
  background-color: #e86e7b;
}
.alien__ear:before {
  position: absolute;
  content: "";
  top: -25px;
  left: -8px;
  width: 25px;
  height: 30px;
  background-color: #e86e7b;
  border-radius: 50%;
}

.spaceships__text{
  /* background-color: #fff;
  width: 200px;
  padding: 0px 5px;
  text-align: center;
  margin: 0 auto;
  font-size: 24px;
  border-radius: 10px; */
  cursor: pointer;
  -webkit-animation: spaceships 8s infinite alternate;
  animation: spaceships 8s infinite alternate;
}

@-webkit-keyframes spaceships {
  0% {
    bottom: calc(50% - 10px);
    -webkit-transform: rotate(0deg) scale(0.5);
            transform: rotate(0deg) scale(0.5);
  }
  25% {
    bottom: calc(50% - 20px);
    -webkit-transform: rotate(10deg) scale(0.5);
            transform: rotate(10deg) scale(0.5);
  }
  50% {
    bottom: calc(50% - 20px);
    -webkit-transform: rotate(0deg) scale(0.5);
            transform: rotate(0deg) scale(0.5);
  }
  75% {
    bottom: calc(50% - 20px);
    -webkit-transform: rotate(-10deg) scale(0.5);
            transform: rotate(-10deg) scale(0.5);
  }
}

@keyframes spaceships {
  0% {
    bottom: calc(50% - 10px);
    -webkit-transform: rotate(0deg) scale(0.5);
            transform: rotate(0deg) scale(0.5);
  }
  25% {
    bottom: calc(50% - 20px);
    -webkit-transform: rotate(10deg) scale(0.5);
            transform: rotate(10deg) scale(0.5);
  }
  50% {
    bottom: calc(50% - 20px);
    -webkit-transform: rotate(0deg) scale(0.5);
            transform: rotate(0deg) scale(0.5);
  }
  75% {
    bottom: calc(50% - 20px);
    -webkit-transform: rotate(-10deg) scale(0.5);
            transform: rotate(-10deg) scale(0.5);
  }
}
@-webkit-keyframes eye {
  0% {
    top: 0px;
  }
  25% {
    top: -90px;
  }
  50% {
    top: 0px;
  }
  75% {
    top: -90px;
  }
  100% {
    top: -90px;
  }
}
@keyframes eye {
  0% {
    top: 0px;
  }
  25% {
    top: -90px;
  }
  50% {
    top: 0px;
  }
  75% {
    top: -90px;
  }
  100% {
    top: -90px;
  }
}