$primary: #881184;//#219e9a;
$light-gray: #ecf0f1;
$medium-gray: #d2d6d5;
$dark-gray: #313131;
$black: #000;
$white: #fff;
/*
 *  Book Rotate
*/

.bk-img {
    position: relative;
    display: inline-block;
    list-style: none;
    .bk-wrapper {
      position: relative;
      width: 150px;
      height: 215px;
      z-index: 1;
      -webkit-perspective: 1400px;
      perspective: 1400px;
      &:last-child {
        margin-right: 0;
      }
    }
    .bk-book {
      position: absolute;
      width: 100%;
      height: 215px;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      -webkit-transition: -webkit-transform .5s;
      transition: transform .5s;
    }
    .bk-book > div,
    .bk-front > div {
      display: block;
      position: absolute;
    }
    .bk-front {
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      -webkit-transform-origin: 0% 50%;
      transform-origin: 0% 50%;
      -webkit-transition: -webkit-transform .5s;
      transition: transform .5s;
      -webkit-transform: translate3d(0, 0, 20px);
      transform: translate3d(0, 0, 20px);
      z-index: 10;
      > div {
        z-index: 1;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        border-radius: 0 3px 3px 0;
        box-shadow: inset 4px 0 10px rgba(0, 0, 0, 0.1);
      }
      &:after {
        content: '';
        position: absolute;
        top: 1px;
        bottom: 1px;
        right: -1px;
        width: 1px;
      }
    }
    .bk-front,
    .bk-back,
    .bk-front > div {
      width: 150px;
      height: 215px;
    }
    .bk-left,
    .bk-right {
      width: 40px;
      right: -20px;
    }
    .bk-back {
      -webkit-transform: rotate3d(0, 1, 0, -180deg) translate3d(0, 0, 20px);
      transform: rotate3d(0, 1, 0, -180deg) translate3d(0, 0, 20px);
      box-shadow: 5px 7px 15px rgba(0, 0, 0, 0.3);
      border-radius: 3px 0 0 3px;
      opacity: 0;
      transition: opacity 250ms ease-out;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 3px;
        background: rgba(0, 0, 0, 0.06);
        box-shadow: 1px 0 3px rgba(255, 255, 255, 0.1);
      }
    }
    .bk-left {
      height: 215px;
      -webkit-transform: rotate3d(0, 1, 0, 90deg);
      transform: rotate3d(0, 1, 0, 90deg);
      h2 {
        width: 232px;
        height: 40px;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: rotate( 90deg   ) translateY(-40px);
        transform: rotate(    90deg ) translateY(-30px);
        margin-right: -202px;
        font-size: 25px;
        color: #fff;
        background: #0000006b;
        line-height: 40px;
      }
      img{
        max-width: unset !important;
        margin-top: -22px;
      }
    }
    .bk-cover {
      /*background-image: url(../images/1.png);*/
      background-repeat: no-repeat;
      background-position: 10px 40px;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 10px;
        bottom: 0;
        width: 3px;
        background: rgba(0, 0, 0, 0.06);
        box-shadow: 1px 0 3px rgba(255, 255, 255, 0.1);
      }
    }
    /* Individual style & artwork */
    .bk-cover {
      background-repeat: no-repeat;
      background-position: top left !important;
    }
    .bk-front > div,
    .bk-left {
      background-color: $primary;
    }
  }


  .book-item {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 15px;
      width: calc(100% - 105px);
      height: 100%;
      border-radius: 3px;
      box-shadow: 0 0 0 0 transparent;
      background-color: rgba($white, 0);
      z-index: -1;
      transition: all 250ms ease-out;
    }
    &:hover {
      .item-img {
        img {
          box-shadow: 0px 0px 10px 0px rgba($dark-gray, 0.25);
        }
      }
      a.button {
        background-color: $primary;
      }
      .bk-bookdefault {
        -webkit-transform: rotate3d(0, 1, 0, -25deg);
        transform: rotate3d(0, 1, 0, -25deg);
      }
      .bk-back {
        opacity: 1;
      }
    }
    .item-img {
      display: inline-block;
      padding-right: 30px;
      img {
        box-shadow: 0 0 0 0 transparent;
        transition: all 250ms ease-out;
      }
    }
    .item-details {
      padding-right: 30px;
    }
}