.Fruit-berry {
    width: 3.7em;
    height: 4em;
    border-radius: 10% 10% 50% 50%;
    position: relative;
    z-index: 1;
}

.Fruit-raspberry {
    background-color: #d33653;
    width: 1.3em;
    height: 3em;
}


.Fruit-raspberry-six-pointed-star {
    --star-color: #539e3d;
    --sqrt-3: 1.73205080757;
    margin: 0 auto;
    font-size: 0.7em;
    width: 0;
    height: 0;
    position: relative;
    border-left: 1em solid transparent;
    border-right: 1em solid transparent;
    border-bottom: calc(1em * var(--sqrt-3)) solid var(--star-color);
    margin-top: -0.2em;
    z-index: 999;
}

.Fruit-raspberry-six-pointed-star::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: -1em;
    top: calc(1em * var(--sqrt-3) / 3);
    border-left: 1em solid transparent;
    border-right: 1em solid transparent;
    border-top: calc(1em * var(--sqrt-3)) solid var(--star-color);
}

.Fruit-berry-ball {
    width: 1.3em;
    height: 1.3em;
    position: absolute;
    border-radius: 50%;
    box-shadow: inset -0.1em -0.1em #00000038;
}

.Fruit-berry-ball:nth-child(1) {
    top: 2.9em;
    left: 1.5em;
}

.Fruit-berry-ball:nth-child(2) {
    top: 3em;
    right: 1.5em;
}

.Fruit-berry-ball:nth-child(3) {
    top: 1.9em;
    left: 1.8em;
}

.Fruit-berry-ball:nth-child(4) {
    top: 1.9em;
    right: 1.8em;
}

.Fruit-berry-ball:nth-child(5) {
    top: 1em;
    left: 2em;
}

.Fruit-berry-ball:nth-child(6) {
    top: 1em;
    right: 2em;
}

.Fruit-berry-ball:nth-child(7) {
    top: 0em;
    left: 1.9em;
}

.Fruit-berry-ball:nth-child(8) {
    top: 0em;
    right: 1.8em;
}

.Fruit-raspberry-leaf-up {
    top: 0.5em;
    left: 2.6em;
    z-index: 2;
}

.Fruit-raspberry-ball:nth-child(2n + 0) {
    box-shadow: inset 0.1em -0.1em #00000078;
}

.Fruit-raspberry-ball {
    background-color: #d33653;
}