.FlyingBee_wrap {
  position: relative;
  height: 60px;
}

@keyframes FlyingBee_dodging1 {
  0% {
    top: 5px;
  }

  50% {
    top: 10px;
  }

  100% {
    top: 5px;
  }
}

.FlyingBee_wrap2 {
  transform: scale(0.5);
}

.FlyingBee_wrap_move {
  position: absolute;
  animation: FlyingBee_dodging1 4s ease-in-out infinite;
}

.FlyingBee_body {
  position: absolute;
  height: 85px;
  right: 50%;
  width: 75px;
  border-radius: 100%;
  background-color: #FFB733;
  box-shadow:
    20px 0 0 #000,
    40px -3px 0 #ffb733,
    60px -7px 0 #000,
    80px -11px 0 #ffb733;
}

@keyframes flutter2 {
  0% {
    transform: rotate(80deg);
  }

  50% {
    transform: rotate(100deg);
  }
}

@keyframes flutter1 {
  0% {
    transform: rotate(20deg);
  }

  50% {
    transform: rotate(40deg);
  }
}

.FlyingBee_wing1 {
  position: absolute;
  height: 55px;
  width: 55px;
  right: 50%;
  top: -45px;
  transform: rotate(20deg);
  transform-origin: bottom right;
  border-top-right-radius: 100%;
  border-bottom-left-radius: 100%;
  border-top-left-radius: 10px;
  background-color: #ddd;
  z-index: -1;
  filter: blur(1px);
  animation: flutter1 0.01s linear reverse infinite;
}

.FlyingBee_wing2 {
  position: absolute;
  height: 55px;
  width: 55px;
  top: -45px;
  right: 50%;
  transform: rotate(80deg);
  transform-origin: bottom right;
  border-top-right-radius: 100%;
  border-bottom-left-radius: 100%;
  border-top-left-radius: 10px;
  background-color: #ddd;
  z-index: 1;
  filter: blur(1px);
  animation: flutter2 0.01s linear infinite;
}

.FlyingBee_stinger {
  position: absolute;
  height: 55px;
  width: 55px;
  left: 140%;
  transform: rotate(320deg);
  border-top-right-radius: 50%;
  border-bottom-left-radius: 100%;
  border-top-left-radius: 10px;
  background-color: #000;
  z-index: -1;
}

.FlyingBee_eyes {
  position: absolute;
  height: 25px;
  width: 25px;
  left: 0;
  background-color: #eee;
  box-shadow:
    20px 0 0 #eee;
  border-radius: 100%;

  .FlyingBee_pupil {
    position: absolute;
    height: 4px;
    width: 4px;
    top: 13px;
    left: 10px;
    background-color: #000;
    border-radius: 100%;
    box-shadow:
      20px 0 0 #000;
  }
}