.MoveTugOfWar {
    animation: TugOfWar-Movment linear forwards 7s normal infinite;
    right: calc(50% - 420px);
    bottom: 3%;
}

@keyframes TugOfWar-Movment {
    0% {
        transform: translateX(0%)
    }

    25% {
        transform: translateX(4%)
    }

    50% {
        transform: translateX(0%)
    }

    75% {
        transform: translateX(-4%)
    }

    100% {
        transform: translateX(0%)
    }
}

.MoveAirplane {
    animation: Airplane-Movment linear forwards 10s normal infinite;
}

@keyframes Airplane-Movment {
    0% {
        left: -30%;
        top: 0%;
    }

    25% {
        left: 10%;
        top: 10%;
    }

    50% {
        left: 40%;
        top: 0%;
    }

    75% {
        left: 70%;
        top: 20%;
    }

    90% {
        left: 90%;
        top: 10%;
    }

    100% {
        left: 120%;
        top: 50%;
    }
}