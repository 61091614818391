.MoveButterfly1 {
    animation: MoveButterfly1-Movment linear forwards 10s normal infinite;
    transform: scaleX(-1);
}

@keyframes MoveButterfly1-Movment {
    0% {
        left: -30%;
        top: 0%;
    }

    25% {
        left: 10%;
        top: 10%;
    }

    50% {
        left: 40%;
        top: 0%;
    }

    75% {
        left: 70%;
        top: 20%;
    }

    90% {
        left: 90%;
        top: 10%;
    }

    100% {
        left: 120%;
        top: 50%;
    }
}


.MoveButterfly2 {
    animation: MoveButterfly2-Movment linear forwards 15s normal infinite;
}

@keyframes MoveButterfly2-Movment {
    0% {
        right: -30%;
        bottom: 10%;
    }

    25% {
        right: 10%;
        bottom: 30%;
    }

    50% {
        right: 40%;
        bottom: 40%;
    }

    75% {
        right: 70%;
        bottom: 30%;
    }

    90% {
        right: 90%;
        bottom: 10%;
    }

    100% {
        right: 120%;
        bottom: 50%;
    }
}