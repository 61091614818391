.BubbleBackground-bubble-field {
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;
    overflow: hidden;
}


@keyframes bubble {
    0% {
        transform: translateX(10px);
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
    }

    50% {
        transform: translateX(-10px);
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
    }

    100% {
        transform: translateX(10px);
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
    }
}

.BubbleBackground-bubble-field .bubble {
    position: relative;
    box-shadow: 0px 0px 12px 5px inset #fff;
    -webkit-box-shadow: 0px 0px 12px 5px inset #fff;
    -moz-box-shadow: 0px 0px 12px 5px inset #fff;
    -o-box-shadow: 0px 0px 12px 5px inset #fff;
    -ms-box-shadow: 0px 0px 12px 5px inset #fff;
    border-radius: 50%;
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
    font-size: 25px;
    animation: bubble 2s ease 0s infinite normal;
}

.BubbleBackground-bubble-field .bubble:after {
    content: '';
    position: absolute;
    background-color: white;
    width: 13px;
    height: 8px;
    top: 10%;
    right: 21%;
    border-radius: 50%;
    transform: rotateZ(30deg) scaleY(0.7);
    -webkit-transform: rotateZ(30deg) scaleY(0.7);
    -moz-transform: rotateZ(30deg) scaleY(0.7);
    -ms-transform: rotateZ(30deg) scaleY(0.7);
    -o-transform: rotateZ(30deg) scaleY(0.7);
    filter: blur(1px);
    -webkit-filter: blur(1px);
}

@keyframes bubble-rise {
    0% {
        transform: translateY(0px);
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
    }

    100% {
        transform: translateY(-1000px);
        -webkit-transform: translateY(-1000px);
        -moz-transform: translateY(-1000px);
        -ms-transform: translateY(-1000px);
        -o-transform: translateY(-1000px);
    }
}

.BubbleBackground-bubble-field .bubble-rise {
    position: relative;
    display: inline-block;
    margin: 15px;
    top: 100%;
    animation: bubble-rise 2s cubic-bezier(0.3, 0, 0.7, 0.75) 0s infinite normal;
}