.Fruit-fruit-lemon {
    background: #fddd09;
    width: 5em;
    height: 5em;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Fruit-fruit-lemon::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    border: 1px solid #e9ce1d;
    box-shadow: 0 0 0.1em 1px rgb(0, 0, 0, 0.4), 0 0 1px 0.2em #fec70c inset,
        0 0 0.2em 0.3em #fce819 inset, 0 0 0.2em 0.5em #fffab9 inset,
        0 0 0.2em 0.7em #fffab9 inset;
    border-radius: 50%;
}


.Fruit-fruit-lemon-slices {
    position: absolute;
    z-index: 5;
    width: 4em;
    height: 0.3em;
    background: #fffab9;
    box-shadow: 0 0 0.05em 1px #fffab9;
}

.Fruit-fruit-lemon-slices::before {
    content: "";
    position: absolute;
    z-index: 5;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: #fffab9;
    transform-origin: center;
    transform: rotate(60deg);
    box-shadow: 0 0 0.05em 1px #fffab9;
}

.Fruit-fruit-lemon-slices::after {
    content: "";
    position: absolute;
    z-index: 5;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: #fffab9;
    transform-origin: center;
    transform: rotate(120deg);
    box-shadow: 0 0 0.05em 1px #fffab9;
}