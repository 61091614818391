.FlyingBirdBackground-background {
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;
    overflow: hidden;
}


.FlyingBirdBackground_RedBird {
    position: absolute;


    animation-timing-function: linear;
    animation-iteration-count: infinite;

    &--one {
        animation-name: FlyingBirdBackground_RedBird_fly-right-one;
        animation-duration: 8s;
        animation-delay: -0.5s;
    }

    &--two {
        animation-name: FlyingBirdBackground_RedBird_fly-right-two;
        animation-duration: 5s;
        animation-delay: -0.75s;
        transform: scaleX(-1);
    }

}

@keyframes FlyingBirdBackground_RedBird_fly-right-one {

    0% {
        top: 90%;
        left: -100%;
    }

    10% {
        top: 10%;
        left: 0%;
        transform: scale(0.4);
    }

    20% {
        top: 20%;
        left: 20%;
        transform: scale(0.6);
    }

    30% {
        top: 25%;
        left: 30%;
        transform: scale(0.9);
    }

    40% {
        top: 20%;
        left: 40%;
        transform: scale(0.8);
    }

    50% {
        top: 30%;
        left: 50%;
        transform: scale(1);
    }

    60% {
        top: 25%;
        left: 60%;
    }

    100% {
        top: 10%;
        left: 200%;
        transform: scale(0.6);
    }

}

@keyframes FlyingBirdBackground_RedBird_fly-right-two {

    0% {
        top: 90%;
        right: -100%;
    }

    10% {
        top: 10%;
        right: 0%;
    }

    20% {
        top: 10%;
        right: 20%;
    }

    30% {
        top: 10%;
        right: 30%;
    }

    40% {
        top: 10%;
        right: 40%;
    }

    50% {
        top: 10%;
        right: 50%;
    }

    60% {
        top: 10%;
        right: 60%;
    }

    90% {
        top: 10%;
        right: 90%;
    }

    100% {
        top: 10%;
        right: 150%;
    }

}