.MoveSubmarine {
    animation-timing-function: ease-out;
    animation: submarine-move 35s infinite;
}

@keyframes submarine-move {
    0% {
        bottom: 15%;
        left: 0%
    }

    25% {
        transform: scaleX(1);
        bottom: 10%;
        left: 95%
    }

    26% {
        transform: scaleX(-1);
        bottom: 10%;
        left: 95%
    }


    50% {
        transform: scaleX(-1);
        bottom: 55%;
        left: 45%
    }

    51% {
        transform: scaleX(1);
        bottom: 55%;
        left: 45%
    }

    75% {
        transform: scaleX(1);
        bottom: 55%;
        left: 80%
    }

    76% {
        transform: scaleX(-1);
        bottom: 56%;
        left: 80%
    }

    100% {
        transform: scaleX(-1);
        bottom: 15%;
        left: 0%
    }
}

.MoveDiver {
    animation-timing-function: ease-in-out;
    animation: diver-move 15s infinite;
}

@keyframes diver-move {
    0% {
        bottom: 15%;
        right: 2%;
    }


    50% {
        bottom: 35%;
        right: 3%
    }

    100% {
        bottom: 15%;
        right: 2%;
    }
}

.MoveFish2 {
    animation-timing-function: ease-in-out;
    animation: fish2-move 15s infinite;
    transform: scaleX(-1);
}

@keyframes fish2-move {
    0% {
        bottom: 35%;
        right: -10%;
    }


    100% {
        bottom: 25%;
        right: 110%;
    }
}

.MoveFish3 {
    animation-timing-function: ease-in-out;
    animation: fish3-move 20s infinite;
}

@keyframes fish3-move {
    0% {
        bottom: 55%;
        left: -10%;
    }


    100% {
        bottom: 15%;
        left: 110%;
    }
}

.MoveFish4 {
    animation-timing-function: ease-in-out;
    animation: fish4-move 10s infinite;
}

@keyframes fish4-move {
    0% {
        bottom: 55%;
        left: -10%;
    }

    50% {
        transform: scaleX(1);
        bottom: 50%;
        left: 50%;
    }

    51% {
        transform: scaleX(-1);
        bottom: 50%;
        left: 50%;
    }

    70% {
        transform: scaleX(-1);
        bottom: 50%;
        left: 5%;
    }

    71% {
        transform: scaleX(1);
        bottom: 50%;
        left: 5%;
    }

    100% {
        bottom: 75%;
        left: 110%;
    }
}



.MoveBird {
    animation-timing-function: ease-in-out;
    animation: bird-move 20s infinite;
}

@keyframes bird-move {
    0% {
        top: 1%;
        left: -10%;
    }


    100% {
        top: 0%;
        left: 110%;
    }
}