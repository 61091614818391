.is-playing-OacenMove {
    animation: move-background 6000s infinite;
    background: #0078E0 url("./bg.png") bottom repeat-x;
    background-size: cover;
}

@keyframes move-background {
    to {
        background-position-x: 999999px;
    }

    from {
        background-position-x: 0px;
    }
}