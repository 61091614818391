
.submarine-container{
position: relative;
animation: subHover 3s 4s infinite linear;
display: flex;
flex-direction: column;
align-items: flex-end;
}
.submarine-submarine {
    direction: ltr;
	font-size: 40rem;
    transform:  scale(0.5);
    top: -210px ;
    position: relative;
    direction: ltr;
    left: calc(50% - 120px);
	}

.submarine-submarine div {
	position:absolute;
	}

.submarine-o-body [class^="submarine-icon-"] {
	color: #fab061;
	z-index: 3;
	position: relative;
	}
.submarine-lt-yellow [class^="submarine-icon-"] {
	color: #fcc589;
	z-index: 5;
	position: relative;
	}

.submarine-periscope [class^="submarine-icon-"] {
	color: #bacad3;
	z-index: 3;
	position: absolute;
  top: 25px;
	animation: periscope 5s ease-in-out 7s 2;
	}

.submarine-propellor [class^="submarine-icon-"] {
	color: #bacad3;
	z-index: 20;
	position: absolute;
	animation: propellorMove 2s linear infinite;
	font-size: 9rem;
    top: 270px ;
    left: 345px;
}


.submarine-tan [class^="submarine-icon-"] {
	color: #d89a54;
	z-index: 8;
	position: relative;
	}

.submarine-dk-brown [class^="submarine-icon-"] {
	color: #552d00;
	z-index: 12;
	position: relative;
	}

.submarine-anchor-window [class^="submarine-icon-"] {
	z-index: 5000;
	position: relative;
	color: #CED8DB;
	}

@keyframes periscope {
  	30%  { transform: translateY(20px); }
	50%  { transform: translateY(50px); }
	70%  { transform: translateY(5px); }
	}

@keyframes propellorMove {
	100% { transform: rotate(360deg); }
	}

@keyframes subHover {
	0%  { transform: translateY(0px) translateX(0px) ; }
  	50%  { transform: translateY(15px) translateX(10px) ; }
	}



/* ICOMOON STUFF */
@font-face {
	font-family: 'icomoon';
	src:url('https://s.cdpn.io/28963/icomoon.eot');
	src:url('https://s.cdpn.io/28963/icomoon.eot?#iefix') format('embedded-opentype'),
		url('https://s.cdpn.io/28963/icomoon.woff') format('woff'),
		url('https://s.cdpn.io/28963/icomoon_1.ttf') format('truetype'),
		url('https://s.cdpn.io/28963/icomoon.svg#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

/* Use the following CSS code if you want to use data attributes for inserting your icons */
[data-icon]:before {
	font-family: 'icomoon';
	content: attr(data-icon);
	speak: none;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
}


/* Use the following CSS code if you want to have a class per icon */
/*
Instead of a list of all class selectors,
you can use the generic selector below, but it's slower:
[class*="icon-"] {
*/
.submarine-icon-cogs, .submarine-icon-cog, .submarine-icon-cog-2, .submarine-icon-spinner, .submarine-icon-sun, .submarine-icon-cloud, .submarine-icon-lightning, .submarine-icon-cloud-2, .submarine-icon-snowflake, .submarine-icon-sun-2, .submarine-icon-chart, .submarine-icon-android, .submarine-icon-marvin, .submarine-icon-pictures, .submarine-icon-zip, .submarine-icon-multiply, .submarine-icon-braillew, .submarine-icon-birdhouse, .submarine-icon-droplets, .submarine-icon-pacman, .submarine-icon-earth, .submarine-icon-dark-brown, .submarine-icon-periscope, .submarine-icon-propellor, .submarine-icon-propellor-2, .submarine-icon-anchor-window, .submarine-icon-light-yellow, .submarine-icon-body, .submarine-icon-tan, .submarine-icon-sea-grass, .submarine-icon-bubbles, .submarine-icon-body2, .submarine-icon-lt-bkgd, .submarine-icon-space-invaders, .submarine-icon-skeletor, .submarine-icon-transformers, .submarine-icon-rocket, .submarine-icon-elipse, .submarine-icon-roundedrectangle, .submarine-icon-polygon, .submarine-icon-zikula, .submarine-icon-oneup, .submarine-icon-vendetta, .submarine-icon-spaceinvaders, .submarine-icon-fort, .submarine-icon-uniF487, .submarine-icon-vinyl, .submarine-icon-matt-hakes-moustache, .submarine-icon-matt-hakes-spectacles, .submarine-icon-propellor2, .submarine-icon-fishes, .submarine-icon-sea-back, .submarine-icon-dark-brown-2, .submarine-icon-jquery, .submarine-icon-restart, .submarine-icon-periscope3, .submarine-icon-periscope2, .submarine-icon-dark-brown3 {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
}
.submarine-icon-cogs:before {
	content: "\21";
}
.submarine-icon-cog:before {
	content: "\22";
}
.submarine-icon-cog-2:before {
	content: "\23";
}
.submarine-icon-spinner:before {
	content: "\24";
}
.submarine-icon-sun:before {
	content: "\25";
}
.submarine-icon-cloud:before {
	content: "\26";
}
.submarine-icon-lightning:before {
	content: "\27";
}
.submarine-icon-cloud-2:before {
	content: "\28";
}
.submarine-icon-snowflake:before {
	content: "\29";
}
.submarine-icon-sun-2:before {
	content: "\2a";
}
.submarine-icon-chart:before {
	content: "\2b";
}
.submarine-icon-android:before {
	content: "\2c";
}
.submarine-icon-marvin:before {
	content: "\2d";
}
.submarine-icon-pictures:before {
	content: "\2e";
}
.submarine-icon-zip:before {
	content: "\2f";
}
.submarine-icon-multiply:before {
	content: "\30";
}
.submarine-icon-braillew:before {
	content: "\31";
}
.submarine-icon-birdhouse:before {
	content: "\32";
}
.submarine-icon-droplets:before {
	content: "\33";
}
.submarine-icon-pacman:before {
	content: "\34";
}
.submarine-icon-earth:before {
	content: "\35";
}
.submarine-icon-dark-brown:before {
	content: "\36";
}
.submarine-icon-periscope:before {
	content: "\37";
}
.submarine-icon-propellor:before {
	content: "\38";
}
.submarine-icon-propellor-2:before {
	content: "\39";
}
.submarine-icon-anchor-window:before {
	content: "\3a";
}
.submarine-icon-light-yellow:before {
	content: "\3b";
}
.submarine-icon-body:before {
	content: "\3c";
}
.submarine-icon-tan:before {
	content: "\3d";
}
.submarine-icon-sea-grass:before {
	content: "\3e";
}
.submarine-icon-bubbles:before {
	content: "\3f";
}
.submarine-icon-body2:before {
	content: "\40";
}
.submarine-icon-lt-bkgd:before {
	content: "\41";
}
.submarine-icon-space-invaders:before {
	content: "\42";
}
.submarine-icon-skeletor:before {
	content: "\43";
}
.submarine-icon-transformers:before {
	content: "\44";
}
.submarine-icon-rocket:before {
	content: "\f29c";
}
.submarine-icon-elipse:before {
	content: "\f1bc";
}
.submarine-icon-roundedrectangle:before {
	content: "\f1bd";
}
.submarine-icon-polygon:before {
	content: "\f1be";
}
.submarine-icon-zikula:before {
	content: "\f0ac";
}
.submarine-icon-oneup:before {
	content: "\f3b7";
}
.submarine-icon-vendetta:before {
	content: "\f3c5";
}
.submarine-icon-spaceinvaders:before {
	content: "\f352";
}
.submarine-icon-fort:before {
	content: "\f400";
}
.submarine-icon-uniF487:before {
	content: "\f487";
}
.submarine-icon-vinyl:before {
	content: "\f0cc";
}
.submarine-icon-matt-hakes-moustache:before {
	content: "\e000";
}
.submarine-icon-matt-hakes-spectacles:before {
	content: "\e001";
}
.submarine-icon-propellor2:before {
	content: "\e002";
}
.submarine-icon-fishes:before {
	content: "\e003";
}
.submarine-icon-sea-back:before {
	content: "\e004";
}
.submarine-icon-dark-brown-2:before {
	content: "\e005";
}
.submarine-icon-jquery:before {
	content: "\f06b";
}
.submarine-icon-restart:before {
	content: "\f11f";
}
.submarine-icon-periscope3:before {
	content: "\e007";
}
.submarine-icon-periscope2:before {
	content: "\e006";
}
.submarine-icon-dark-brown3:before {
	content: "\e008";
}
