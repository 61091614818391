.MoveTugOfWar {
    animation: TugOfWar-Movment linear forwards 7s normal infinite;
    right: calc(50% - 420px);
    bottom: 3%;
}

@keyframes TugOfWar-Movment {
    0% {
        transform: translateX(0%)
    }

    25% {
        transform: translateX(4%)
    }

    50% {
        transform: translateX(0%)
    }

    75% {
        transform: translateX(-4%)
    }

    100% {
        transform: translateX(0%)
    }
}

.MoveDuck {
    animation: Duck-Movment linear forwards 20s normal infinite;
}

@keyframes Duck-Movment {
    0% {
        left: -30%;
        bottom: 0%;
    }

    25% {
        left: 10%;
        bottom: 5%;
    }

    50% {
        left: 40%;
        bottom: 2%;
    }

    75% {
        left: 70%;
        bottom: 6%;
    }

    90% {
        left: 90%;
        bottom: 5%;
    }

    100% {
        left: 120%;
        bottom: 0%;
    }
}

.MoveBlueBird {
    animation: BlueBird-Movment linear forwards 15s normal infinite;
}

@keyframes BlueBird-Movment {
    0% {
        left: -30%;
        top: 0%;
    }

    25% {
        left: 10%;
        top: 5%;
    }

    50% {
        left: 40%;
        top: 2%;
    }

    75% {
        left: 70%;
        top: 6%;
    }

    90% {
        left: 90%;
        top: 5%;
    }

    100% {
        left: 120%;
        top: 0%;
    }
}

.MoveEgale {
    transform: scaleX(-1);
    animation: Egale-Movment linear forwards 20s normal infinite;
}

@keyframes Egale-Movment {
    0% {
        right: -30%;
        top: 0%;
    }

    25% {
        right: 10%;
        top: 15%;
    }

    50% {
        right: 40%;
        top: 12%;
    }

    75% {
        right: 70%;
        top: 36%;
    }

    90% {
        right: 90%;
        top: 15%;
    }

    100% {
        right: 120%;
        top: 40%;
    }
}

.MoveblackBird {
    animation: blackBird-Movment linear forwards 10s normal infinite;
}

@keyframes blackBird-Movment {
    0% {
        right: 40%;
        top: 2%;
    }

    25% {
        right: 50%;
        top: 5%;
        transform: rotate(15deg);
    }

    50% {
        right: 60%;
        top: 1%;
        transform: rotate(15deg);
    }

    75% {
        right: 50%;
        top: 5%;
        transform: rotate(-15deg);
    }

    100% {
        right: 40%;
        top: 2%;
    }
}