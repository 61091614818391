.MoveTugOfWar {
    animation: TugOfWar-Movment linear forwards 7s normal infinite;
    right: calc(50% - 420px);
    bottom: 3%;
}

@keyframes TugOfWar-Movment {
    0% {
        transform: translateX(0%)
    }

    25% {
        transform: translateX(4%)
    }

    50% {
        transform: translateX(0%)
    }

    75% {
        transform: translateX(-4%)
    }

    100% {
        transform: translateX(0%)
    }
}

.MoveAirplane-Rank {
    animation: Airplane-Movment-Rank linear forwards 10s normal infinite;
}

@keyframes Airplane-Movment-Rank {
    0% {
        left: -30%;
        top: 10%;
    }

    25% {
        left: 10%;
        top: 35%;
    }

    50% {
        left: 40%;
        top: 25%;
    }

    75% {
        left: 70%;
        top: 30%;
    }

    90% {
        left: 90%;
        top: 15%;
    }

    100% {
        left: 120%;
        top: 50%;
    }
}


.MoveBlueBird-Rank {
    animation: BlueBird-Movment-Rank linear forwards 15s normal infinite;
}

@keyframes BlueBird-Movment-Rank {
    0% {
        left: -30%;
        top: 10%;
    }

    25% {
        left: 10%;
        top: 15%;
    }

    50% {
        left: 40%;
        top: 12%;
    }

    75% {
        left: 70%;
        top: 16%;
    }

    90% {
        left: 90%;
        top: 15%;
    }

    100% {
        left: 120%;
        top: 10%;
    }
}