@media screen and (min-width: 768px) {
    .book_pages
    {
        background: none repeat scroll 0 0 #FFFFFF;
        border: 1px solid #d0cece;
        border-radius: 7px 7px 7px 7px;
        margin-top: -1px;
        z-index: 20;
        padding: 0 2px;
        box-shadow: 0 -6px 7px -10px #333333;
        position: relative;
    }
    .page_bind {
        position: relative;
        top: 5px;
        float: right;
        z-index: 100;
    }
    .page_bind:after{
        background: linear-gradient(to right, transparent 0%,rgba(0,0,0,0.2) 46%,rgba(0,0,0,0.5) 49%,rgba(0,0,0,0.6) 50%,rgba(0,0,0,0.5) 51%,rgba(0,0,0,0.2) 52%,transparent 100%);
        bottom: 0px;
        content: '';
        left: 50%;
        position: absolute;
        top: -11px;
        transform: translate(-50%,0);
        width: 2em;
        z-index: 1;
    }
    .page_bind .top {
        background-image: url(./sprites.png);
        background-position: 0 5px;
        float: left;
        height: 30px;
        left: -12px;
        position: absolute;
        top: 25px;
        width: 20px;
        z-index: 200;
    }.page_bind .bottom {
        background-image: url(sprites.png);
        background-position: 0 5px;
        float: left;
        height: 30px;
        left: -12px;
        position: absolute;
        bottom: 25px;
        width: 20px;
        z-index: 200;
    }
    .open-page
    {
        padding: 0px;
    }
    .page_left {
        background: -moz-radial-gradient(center top , #FFFFFF, #FFFFFF) repeat scroll 0 0 transparent;
        background: -webkit-radial-gradient(center top , #FFFFFF, #FFFFFF) repeat scroll 0 0 transparent;
        background: -o-radial-gradient(center top , #FFFFFF, #FFFFFF) repeat scroll 0 0 transparent;
        background: -ms-radial-gradient(center top , #FFFFFF, #FFFFFF) repeat scroll 0 0 transparent;
        background: -radial-gradient(center top , #FFFFFF, #FFFFFF) repeat scroll 0 0 transparent;
        border-radius: 5px 0 0 5px;
        position: relative;
    }
    .page_right {
        background: -moz-radial-gradient(center top , #FFFFFF, #FFFFFF) repeat scroll 0 0 transparent;
        background: -webkit-radial-gradient(center top , #FFFFFF, #FFFFFF) repeat scroll 0 0 transparent;
        background: -o-radial-gradient(center top , #FFFFFF, #FFFFFF) repeat scroll 0 0 transparent;
        background: -ms-radial-gradient(center top , #FFFFFF, #FFFFFF) repeat scroll 0 0 transparent;
        background: -radial-gradient(center top , #FFFFFF, #FFFFFF) repeat scroll 0 0 transparent;
        border-radius: 0 5px 5px 0;
        overflow: hidden;
        position: relative;
    }
    .book_cover {
        background: #92278f;
        border: 1px solid #40033e;
        border-radius: 10px 10px 10px 10px;
        box-shadow: 1px 0 1px #BCBCBC inset;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        padding: 10px;
    }

}
@media screen and (max-width: 767px)
{
    .page_right,.page_left
    {
        position: relative;
        margin-bottom: 2.143rem;
        background-color: #fff;
        border: 0 solid transparent;
        border-radius: .286rem;
        -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
        box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
        z-index: 9;
    }
}
@media (max-width: 1550px) {
    .bookWapper {
        max-width: 91.666667%;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.666667%;
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
    }
    .singlePage{
        max-width: 68.333333%;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 78.333333%;
        -ms-flex: 0 0 78.333333%;
        flex: 0 0 68.333333%;
    }
}

@media (min-width: 1550px) {
    .bookWapper {
        max-width: 83.333333%;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 83.333333%;
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
    }
    .singlePage{
        max-width: 58.333333%;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.333333%;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
    }
}
@media (min-width: 1100px) {
    .bookPageWapper {
        width: 50%;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    }
    .page_right {
        float: right !important;
    }
    .page_left {
        float: left !important;
    }
    .bookPageInnerWapperRight {
        padding-right: 0px !important;
    }
    .controlBook {
        display: none !important;
    }
}
@media (max-width:1200px) {
    .pencil_box {
        position: relative;
    }

    #pencil_wrapper {
        -ms-transform: rotate(90deg); /* IE 9 */
        -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
        transform: rotate(90deg);
        transform-origin: -200% 60%;
    }
    .singlePage{
        max-width: 78.333333%;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 78.333333%;
        -ms-flex: 0 0 78.333333%;
        flex: 0 0 78.333333%;
    }
}
@media (max-width:1200px) {
    .singlePage{
        max-width: 85.333333%;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 85.333333%;
        -ms-flex: 0 0 85.333333%;
        flex: 0 0 85.333333%;
    }
}
@media (max-width:1200px) {
    .pencil_box {
        display: none;
    }
}
@media (max-width:770px) {
    .book_pages {
        background: #fff;
        padding: 1px !important;
    }
}
@media (max-width:440px) {
    .book_cover {
        border-radius: 5px;
    }
    .page-inner{
        padding: 0 !important;
    }
    .page_left, .page_right{
        margin-bottom: 0;
    }
}
@media (min-width:1200px) and (max-width: 1350px) {
    .bookWapper, .singlePage{
        max-width: 97.5%  !important;
        flex: 0 0 97.5% !important;
    }
}
@media (min-width:1350px) and (max-width: 1550px) {
    .bookWapper, .singlePage{
        max-width: 96%  !important;
        flex: 0 0 96% !important;
    }
}
@media (min-width:1550px) and (max-width: 1800px) {
    .bookWapper, .singlePage{
        max-width: 91%  !important;
        flex: 0 0 91% !important;
    }
}
@media (max-width:770px) {
    .paper {
        padding-left: 0px;
        padding-right: 0px;
        overflow: hidden;
    }
    .bookWapper, .singlePage {
        width: 100%;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    }
    .singlePage {
        position: relative;
        margin-bottom: 2.143rem;
        background-color: #fff;
        border: 0 solid transparent;
        border-radius: .286rem;
        -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
        box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
        box-shadow: 1px -3px 20px 2px #dedede inset;
    }
}
@media (max-width: 1200px) {
    .page_right, .page_left {
        float: none !important;
    }

    .toolbar {
        display: none !important;
    }

    .Pagecontrol {
        display: none !important;
    }

    .hidePage {
        display: none !important;
    }

    .controlBook {
        display: block !important;
    }

    .bookPageInnerWapper {
        padding: 10px !important;
    }

    .page_bind {
        display: none;
    }

    .bookPageWapper {
        width: 100%;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    }
}
#canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    z-index: 8;
    overflow-x: hidden;
}
.full-h{
    min-height: 80vh;
}
.page-inner{
    padding: 2rem;
}
#book-footer:before,  #book-footer:after{
    background: #fff;
    border-radius: 25%;
    content: '';
    height: 2em;
    position: absolute;
    z-index: -1;
    width: 50%;
    bottom: 0px;
}
#book-footer:before{
    transform: rotate(-2deg);
    left: 50%;
}
#book-footer:after{
    transform: rotate(2deg);
    right: 50%;
}

#book-header:before, #book-header:after{
    background: #fff;
    border-radius: 25%;
    content: '';
    height: 2em;
    position: absolute;
    z-index: -1;
    width: 50%;
    transform: rotate(2deg);
}

#book-header:before{
    transform: rotate(-2deg);
    right: 50%;
}

#tools li{
    font-size: 20px;
    border: 1px solid #707077;
    margin: 1px 5px;
}
.mark, mark {
    background-color: #f16421;
}
.PopoverSearch .popover {
    width: 300px;
    max-width: 300px;
}
.PopoverSearch .popover .popover-inner input{
    border: 1px solid #757575;
    font-weight: bold;

}
.bookWapper{
    box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),    0 8px 16px -8px rgba(0, 0, 0, 0.3);
}
.scrollbar-container {
    opacity: 0!important;
}
.scrollarea:hover .scrollbar-container {
    opacity: .7!important;
}
.scrollarea .scrollbar-container.vertical{
    left: 10px;
    right: unset !important;
}
.scrollarea .scrollbar-container.active, .scrollarea .scrollbar-container:hover {
    background: #ce9ecc;
    opacity: 0.9!important;
}
.scrollarea .scrollbar-container.vertical .scrollbar{
    background: #8f278c!important;
    margin-right: 1px;
    z-index: 99999;
}
.scrollarea .scrollbar-container.active, .scrollarea .scrollbar-container:hover {
    background: #ce9ecc;
    opacity: .9!important;
}