.Fruit-orange {
    width: 5.6em;
    height: 5.6em;
    border-radius: 50%;
    background-color: #ffb526;
    box-shadow: inset 0.2em -0.2em #cc8a0c;
}

.Fruit-orange:before {
    content: "";
    position: absolute;
    top: -1em;
    left: 3em;
    height: 0;
    width: 0;
    border: 1em solid #539e3d;
    border-radius: 50% 0 50% 0;
    border-bottom-color: #437512;
    border-right-color: #437512;
}