.Fruit-apple {
    width: 6em;
    height: 5em;
    background-color: #ff4355;
    box-shadow: inset 0.2em -0.2em #0000008a;
    border-radius: 2em 2em 3em 3em;
}

.Fruit-apple:before {
    top: -0.2em;
    left: 1.8em;
    content: "";
    width: 4.1em;
    height: 2.75em;
    background-color: inherit;
    border-radius: 3em 3em 0 0;
    position: absolute;
    box-shadow: -1.5em 0 0 #ff4355, inset 0.2em 0.2em #ff4355;
}

.Fruit-apple:after {
    content: "";
    width: 0.2em;
    height: 1em;
    background-color: brown;
    position: absolute;
    z-index: 2;
    top: -0.5em;
    left: 3em;
}