@keyframes JellyFish_swim_move {
    20% {
        transform: translateY(3em);
    }

    45% {
        transform: translateY(0em);
    }

    100% {
        transform: translateY(3em);
    }
}

@keyframes JellyFish_squash_move {
    20% {
        transform: scaleY(0.9);
    }

    40% {
        transform: scaleY(1.1);
    }

    60% {
        transform: scaleY(0.9);
    }
}

.JellyFish-datouwang {
    position: relative;
    filter: drop-shadow(0 0 1em cyan);
    height: 10em;
    animation: JellyFish_swim_move 5s ease-in-out infinite;
    transform-origin: 50% 0;
    transform: translateY(3em);
    direction: ltr;
}

.JellyFish-datouwang-text {
    animation: JellyFish_swim_move 5s ease-in-out infinite;
    transform-origin: 50% 0;
    transform: translateY(3em);
}

.JellyFish-datouwang .JellyFish-arms {
    transform-origin: 50% 0;
    animation: JellyFish_squash_move 5s ease-in-out infinite;
}

.JellyFish-datouwang .JellyFish-arms .JellyFish-arm {
    position: absolute;
    width: 2em;
    height: 4.2em;
    top: 3em;
    border-radius: 50%;
    border-left: 1em solid #ccffff;
    border-bottom: 0.3em solid transparent;
    transform-origin: 50% 0;
    opacity: 0.4;
}

.JellyFish-datouwang .JellyFish-arms .JellyFish-arm:before {
    content: '';
    position: absolute;
    width: 1.6em;
    height: 3em;
    top: 3em;
    left: -1.2em;
    border-radius: 50%;
    border-right: 0.6em solid #ccffff;
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    transform: rotate(-10.4deg);
}

.JellyFish-datouwang .JellyFish-arms .JellyFish-arm:after {
    content: '';
    position: absolute;
    width: 1em;
    height: 2em;
    top: 5.2em;
    right: 0.04em;
    border-radius: 50%;
    border-top: 0.4em solid transparent;
    border-left: 0.4em solid #ccffff;
    transform: rotate(10deg);
}

@keyframes JellyFish_wave_move_1 {
    30% {
        transform: scaleX(1) scaleY(1) rotate(calc(3 * -1deg));
    }
}

.JellyFish-datouwang .JellyFish-arms .JellyFish-arm:nth-child(1) {
    left: 2.2em;
    transform: scaleX(1) scaleY(1) rotate(calc(1.5 * 1deg));
    animation: JellyFish_wave_move_1 10s ease-in-out infinite;
}

@keyframes JellyFish_wave_move_2 {
    30% {
        transform: scaleX(1) scaleY(1.2) rotate(calc(2 * 1deg));
    }
}

.JellyFish-datouwang .JellyFish-arms .JellyFish-arm:nth-child(2) {
    left: 3em;
    transform: scaleX(1) scaleY(1.2) rotate(calc(2 * 1deg));
    animation: JellyFish_wave_move_2 10s ease-in-out infinite;
}

@keyframes JellyFish_wave_move_3 {
    30% {
        transform: scaleX(1) scaleY(1.15) rotate(calc(1 * 1deg));
    }
}

.JellyFish-datouwang .JellyFish-arms .JellyFish-arm:nth-child(3) {
    left: 3.6em;
    transform: scaleX(1) scaleY(1.15) rotate(calc(1 * 1deg));
    animation: JellyFish_wave_move_3 10s ease-in-out infinite;
}

@keyframes JellyFish_wave_move_4 {
    30% {
        transform: scaleX(1) scaleY(1.15) rotate(calc(0 * 1deg));
    }
}

.JellyFish-datouwang .JellyFish-arms .JellyFish-arm:nth-child(4) {
    left: 3.3em;
    transform: scaleX(1) scaleY(1.15) rotate(calc(0 * 1deg));
    animation: JellyFish_wave_move_4 10s ease-in-out infinite;
}

@keyframes JellyFish_wave_move_5 {
    30% {
        transform: scaleX(1) scaleY(1.1) rotate(calc(-1 * 1deg));
    }
}

.JellyFish-datouwang .JellyFish-arms .JellyFish-arm:nth-child(5) {
    left: 4em;
    transform: scaleX(1) scaleY(1.1) rotate(calc(-1 * 1deg));
    animation: JellyFish_wave_move_5 10s ease-in-out infinite;
}

@keyframes JellyFish_wave_move_6 {
    30% {
        transform: scaleX(-1) scaleY(1.1) rotate(calc(-2 * 1deg));
    }
}

.JellyFish-datouwang .JellyFish-arms .JellyFish-arm:nth-child(6) {
    left: 4.5em;
    transform: scaleX(-1) scaleY(1) rotate(calc(-2 * 1deg));
    animation: JellyFish_wave_move_6 10s ease-in-out infinite;
}

@keyframes JellyFish_wave_move_7 {
    30% {
        transform: scaleX(-1) scaleY(1.15) rotate(calc(-1.5 * 1deg));
    }
}

.JellyFish-datouwang .JellyFish-arms .JellyFish-arm:nth-child(7) {
    left: 5em;
    transform: scaleX(-1) scaleY(1.15) rotate(calc(-3 * 1deg));
    animation: JellyFish_wave_move_7 10s ease-in-out infinite;
}

@keyframes JellyFish_wave_move_8 {
    30% {
        transform: scaleX(0.5) scaleY(1.14) rotate(calc(-4 * 1deg));
    }
}

.JellyFish-datouwang .JellyFish-arms .JellyFish-arm:nth-child(8) {
    left: 5.5em;
    transform: scaleX(0.5) scaleY(1.14) rotate(calc(-4 * 1deg));
    animation: JellyFish_wave_move_8 10s ease-in-out infinite;
}

.JellyFish-datouwang .JellyFish-tentacles {
    animation: JellyFish_squash_move 10s ease-in-out infinite;
}

.JellyFish-datouwang .JellyFish-tentacles .JellyFish-tentacle {
    position: absolute;
    top: 4em;
    width: 10em;
    height: 8em;
    border: 1px solid rgba(0, 255, 255, 0.6);
    border-bottom: none;
    border-top: none;
    border-bottom-left-radius: 10% 50%;
    border-bottom-right-radius: 10% 50%;
    border-top-left-radius: 20% 50%;
    border-top-right-radius: 20% 50%;
}

.JellyFish-datouwang .JellyFish-tentacles .JellyFish-tentacle:nth-child(2) {
    transform: scaleX(0.8);
}

.JellyFish-datouwang .JellyFish-tentacles .JellyFish-tentacle:nth-child(3) {
    transform: scaleX(0.6);
}

.JellyFish-datouwang .JellyFish-tentacles .JellyFish-tentacle:nth-child(4) {
    transform: scaleX(0.4);
}

.JellyFish-datouwang .JellyFish-tentacles .JellyFish-tentacle:nth-child(5) {
    transform: scaleX(0.2);
}

.JellyFish-datouwang .JellyFish-guts {
    width: 4em;
    height: 4em;
    background: white;
    position: absolute;
    left: 3em;
    top: 1em;
    border-radius: 50%;
    box-shadow: -1.5em 0.7em 0 -0.8em white, -1.7em -0.5em 0 -1.4em white, 1.8em -0.1em 0 -1.3em white, 1em 1em 0 -0.6em white, -0.4em 1.8em 0 -1.3em white, 1.4em -1em 0 -1.5em white, -1.3em 1.8em 0 -1.5em white;
    filter: drop-shadow(0 0 0.5em rgba(255, 255, 255, 0.5));
}

.JellyFish-datouwang .JellyFish-body {
    background: cyan;
    width: 10em;
    height: 5em;
    border-top-left-radius: 50% 100%;
    border-top-right-radius: 50% 100%;
    position: relative;
    opacity: 0.65;
    /* box-shadow: inset 0 0.2em 0 cyan, inset 0 0.4em 0 hsla(0, 0%, 100%, 0.6); */
    transform-origin: 50% 50%;
    animation: JellyFish_squash_move 10s ease-in-out infinite;
}

.JellyFish-datouwang .JellyFish-body .JellyFish-base {
    position: absolute;
    width: 3em;
    height: 1.5em;
    background: cyan;
    bottom: -1.5em;
    border-bottom-left-radius: 50% 100%;
    border-bottom-right-radius: 50% 100%;
    box-shadow: 3.5em 0 0 cyan, 7em 0 0 cyan;
}

.JellyFish-datouwang .JellyFish-body .JellyFish-base:after {
    content: '';
    position: absolute;
    width: 3em;
    height: 1em;
    left: 1.75em;
    top: -0.25em;
    background: radial-gradient(circle at 50% 120%, rgba(0, 255, 255, 0) 0, rgba(0, 255, 255, 0) 25%, cyan 10%, cyan 100%);
}

.JellyFish-datouwang .JellyFish-body .JellyFish-base:before {
    content: '';
    position: absolute;
    width: 3em;
    height: 1em;
    left: 5.25em;
    top: -0.25em;
    background: radial-gradient(circle at 50% 120%, rgba(0, 255, 255, 0) 0, rgba(0, 255, 255, 0) 25%, cyan 10%, cyan 100%);
}

@keyframes JellyFish_JellyFish-eyes_move {
    40% {
        transform: translateY(-0.2em);
    }

    60% {
        transform: translateY(0.2em);
    }
}

.JellyFish-datouwang .JellyFish-eyes .JellyFish-eye {
    width: 2em;
    height: 2em;
    background: white;
    position: absolute;
    left: 2.85em;
    top: 3em;
    border-radius: 50%;
    overflow: hidden;
    transform: translateZ(0);
}

.JellyFish-datouwang .JellyFish-eyes .JellyFish-eye:before {
    content: '';
    position: absolute;
    width: 70%;
    height: 70%;
    background: darkblue;
    border-radius: 50%;
    left: 15%;
    top: 15%;
    animation: JellyFish_JellyFish-eyes_move 10s ease-in-out infinite;
}

.JellyFish-datouwang .JellyFish-eyes .JellyFish-eye:after {
    content: '';
    position: absolute;
    width: 25%;
    height: 25%;
    background: #fff;
    border-radius: 50%;
    left: 25%;
    top: 25%;
    box-shadow: 0.4em 0.4em 0 -0.12em white, 0.5em 0.05em 0 -0.15em white;
    animation: JellyFish-eyes 10s ease-in-out infinite;
    z-index: 1;
}

.JellyFish-datouwang .JellyFish-eyes .JellyFish-eye.JellyFish-right {
    left: 5.15em;
}

.JellyFish-datouwang .JellyFish-eyes .JellyFish-eyelid {
    width: 100%;
    height: 100%;
    background: cyan;
    z-index: 2;
    position: relative;
    transform: translateY(-3em);
    animation: JellyFish_blink_move 6s ease-in-out infinite;
}

@keyframes JellyFish_blink_move {
    97% {
        transform: translateY(-3em);
    }

    100% {
        transform: translateY(0em);
    }
}