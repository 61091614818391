.Fruit-Strawberry {
    width: 5em;
    height: 5em;
    margin-top: 0.7em;
    background-color: #ed2607;
    border-radius: 50% 15% 50% 50%;
    transform: rotate(135deg);
    box-shadow: inset -0.15em -0.25em #c62106;
}

.Fruit-Strawberry::before {
    content: "";
    position: absolute;
    top: 3.5em;
    left: 2em;
    width: 0.3em;
    height: 0.3em;
    background-color: white;
    border-radius: 50%;
    box-shadow: 1.7em 0 0 0 white, -1em -2.8em 0 0 white, 2.5em -1.5em 0 0 white,
        2em -2.5em 0 0 white, 0.75em -1.5em 0 0 white, -1em -1em 0 0 white,
        0.8em -3.2em 0 0 white;
}


.Fruit-six-pointed-star {
    --star-color: #539e3d;
    --sqrt-3: 1.73205080757;
    margin: 0 auto;
    font-size: 0.7em;
    width: 0;
    height: 0;
    position: relative;
    border-left: 1em solid transparent;
    border-right: 1em solid transparent;
    border-bottom: calc(1em * var(--sqrt-3)) solid var(--star-color);
    z-index: 999;
    position: absolute;
    left: 0.1em;
    bottom: 0.7em;
}

.Fruit-six-pointed-star::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: -1em;
    top: calc(1em * var(--sqrt-3) / 3);
    border-left: 1em solid transparent;
    border-right: 1em solid transparent;
    border-top: calc(1em * var(--sqrt-3)) solid var(--star-color);
}