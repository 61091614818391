.Fruit-banana {
    width: 6em;
    height: 6em;
    background-color: transparent;
    box-shadow: -1.1em 1.1em 0 0 #fcd110;
    border-radius: 50%;
}

.Fruit-banana:before {
    content: "";
    width: 0.3em;
    height: 0.3em;
    transform: rotate(45deg);
    background-color: #937714;
    position: absolute;
    top: 5.4em;
    left: 4.3em;
    z-index: 1;
}

.Fruit-banana:after {
    content: "";
    width: 0.4em;
    height: 0.8em;
    transform: rotate(31deg);
    background-color: #b3bb03;
    position: absolute;
    top: 1.1em;
    left: 0.2em;
    z-index: 1;
}

.Fruit-banana-line {
    width: 5.5em;
    height: 5.8em;
    background-color: transparent;
    box-shadow: -0.4em 0.4em 0 0 #ffe23c;
    border-radius: 50%;
    position: absolute;
    top: 0.4em;
    left: -0.1em;
    transform: rotate(-4deg);
}