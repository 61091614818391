

.CartoonBox_container{
	display: flex;
    flex-direction: column;
    align-items: center;
}
.CartoonBox_waper{
	transform: scale(0.4);
	position: relative;
	width: 100%;
    height: 100%;
}
.CartoonBox_waper:hover{
	animation: 0.6s dance linear infinite;
}
.CartoonBox_noodles {
	position: relative;
	transform: rotateX(-10deg) rotateY(25deg);
	transform-style: preserve-3d;
}
.CartoonBox_noodles * {
	position: absolute;
	transform-style: preserve-3d;
}

.CartoonBox_side {
	--main: #fff;
	width: 250px;
	height: 250px;
	background-color: var(--main);
	border: solid #CAC4C6;
	border-width: 2px 0;
	transform: rotateX(-5deg);
	top: -175px;
	left: -75px;
}
.CartoonBox_side.CartoonBox_front {
	transform: translateZ(69px) rotateX(-5deg);
	display: flex;
	justify-content: center;
	align-items: center;
}
.CartoonBox_side.CartoonBox_left {
	--main: #F6E2E3;
	transform: translateZ(-69px) translateX(-135px) rotateY(90deg) rotateX(5deg);
}
.CartoonBox_side.CartoonBox_right, .CartoonBox_side.CartoonBox_back {
	--main: #DF807B;
}
.CartoonBox_side.CartoonBox_right {
	transform: translateZ(-69px) translateX(135px) rotateY(90deg) rotateX(-5deg);
}
.CartoonBox_side.CartoonBox_back {
	transform: translateZ(-205px) rotateX(5deg);
}
.CartoonBox_side:before, .CartoonBox_side:after {
	content: "";
	height: 100%;
	width: 30px;
	background-color: var(--main);
	position: absolute;
	top: -2px;
	left: -12px;
	transform: skewX(5deg);
	border: solid #CAC4C6;
	border-width: 2px 0 2px 2px;
}
.CartoonBox_side:after {
	left: initial;
	right: -12px;
	transform: skewX(-5deg);
	border-width: 2px 2px 2px 0;
}
.CartoonBox_eyes {
	height: 20px;
	width: 20px;
	border-radius: 50%;
	background-color: #312D2A;
	top: 150px;
	left: 65px;
	box-shadow: 110px 0 #312D2A;
	animation: 4s eyes 1s linear infinite;
}
.CartoonBox_mouth {
	background-color: #312D2A;
	overflow: hidden;
	width: 50px;
	height: 20px;
	left: 105px;
	top: 165px;
	border-radius: 5px 5px 50% 50%;
}
.CartoonBox_mouth:after {
	content: "";
	position: absolute;
	width: 14px;
	height: 14px;
	border-radius: 50%;
	background-color: #DE5C65;
	left: 17px;
	bottom: -8px;
}

/*
* Top part of cup
*/
.CartoonBox_cap {
	--main: #fff;
	width: 290px;
	height: 100px;
	background-color: var(--main);
	border: solid #CAC4C6;
	border-width: 0 2px;
    top: -274px;
	left: -97px;
	transform-origin: bottom;
	animation: 0.3s ease-out cap infinite alternate;
}
.CartoonBox_cap:before, .CartoonBox_cap:after {
	content: "";
	height: 50px;
	width: 180px;
	position: absolute;
	top: -52px;
}
.CartoonBox_cap:before {
    left: 84px;
	background-color: var(--main);
	border: solid #CAC4C6;
	border-width: 2px 2px 0 0;
	transform: skewX(45deg);
}
.CartoonBox_cap:after {
    right: 84px;
	background-color: var(--main);
	border: solid #CAC4C6;
	border-width: 2px 0 0 2px;
	transform: skewX(-45deg);
}
.CartoonBox_cap.CartoonBox_front {
	--rotate: translateZ(80px) rotateX(-85deg);
	transform: translateZ(80px) rotateX(-125deg);
}
.CartoonBox_cap.CartoonBox_back {
	--main: #F6E2E3;
	--rotate: translateZ(-216px) rotateX(85deg);
	transform: translateZ(-216px) rotateX(125deg);
}
.CartoonBox_cap.CartoonBox_left {
	--rotate: translateZ(-60px) translateX(-150px) rotateY(-90deg) rotateX(-115deg);
	top: -305px;
	height: 130px;
	transform: translateZ(-60px) translateX(-150px) rotateY(-90deg) rotateX(-125deg);
}
.CartoonBox_cap.CartoonBox_right {
	--rotate: translateZ(-60px) translateX(148px) rotateY(90deg) rotateX(-115deg);
	--main: #F6E2E3;
	top: -305px;
	height: 130px;
	transform: translateZ(-60px) translateX(148px) rotateY(90deg) rotateX(-125deg);
}


@keyframes dance {
	0% {transform: scale(0.4) rotateX(-10deg) rotateY(25deg);}
	25% {transform: scale(0.4) translateY(20px) rotateX(-10deg) rotateY(35deg) rotateZ(10deg);}
	50% {transform: scale(0.4) rotateX(-10deg) rotateY(35deg);}
	75% {transform: scale(0.4) translateY(20px)rotateX(-10deg) rotateY(25deg) rotateZ(-10deg);}
	100% {transform: scale(0.4) rotateX(-10deg) rotateY(25deg);}
}
@keyframes eyes {
	2% {}
	4% {
		height: 4px;
		transform: translateY(8px);
	}
	6% {
		height: 20px;
		transform: initial;
	}
}
@keyframes noodles {
	to {top: var(--top)}
}
@keyframes cap {
	to {
		transform: var(--rotate);
	}
}