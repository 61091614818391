$color1: #306D85;
$color2: #D93A54;
$color3: #f7c1bc;

.SubmarineFish {

    .circle-wrapper {
        position: relative;
        width: 200px;
        height: 120px;
        transform: scale(0.7);
    }

    .submarine-wrapper {
        animation: diving 3s ease-in-out infinite, diving-rotate 3s ease-in-out infinite;

        .submarine-body {

            width: 150px;
            height: 80px;
            position: absolute;
            margin-top: 50px;
            left: 25px;
            background-color: $color2;
            border-radius: 40px;
            background: linear-gradient($color2, darken($color2, 10%));

            .light {
                position: absolute;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 40px 150px 40px;
                border-color: transparent transparent lighten($color3, 5%) transparent;

                transform: rotate(-50deg);
                top: 40px;
                left: 99%;

                &:after {
                    content: "";
                    position: absolute;
                    width: 20px;
                    height: 13px;
                    border-radius: 5px;
                    background-color: darken($color2, 5%);
                    margin-left: -10px;
                }

            }

            .window {
                width: 37px;
                height: 37px;
                position: absolute;
                margin-top: 23px;
                right: 18px;
                background: linear-gradient(darken($color1, 13%), darken($color1, 18%));
                border-radius: 50%;
                border: 3px solid $color2;

                &:after {
                    content: "";
                    position: absolute;
                    margin-top: 3px;
                    margin-left: 3px;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    background-color: transparent;
                    opacity: 0.8;
                    border-top: 3px solid white;
                    transform: rotate(-45deg);
                }
            }

            .engine {
                width: 30px;
                height: 30px;
                position: absolute;
                margin-top: 32px;
                left: 53px;
                background-color: darken($color2, 10%);
                border-radius: 50%;
                border: 5px solid $color2;

                &:after,
                &:before {
                    position: absolute;
                    content: "";
                    border-radius: 2px;
                    background-color: white;
                    animation: spin 900ms linear infinite;
                    opacity: 0.8;
                }

                &:after {
                    top: 8px;
                    width: 20px;
                    height: 4px;

                }

                &:before {
                    left: 8px;
                    width: 4px;
                    height: 20px;

                }
            }
        }

        .helix {
            width: 30px;
            height: 70px;
            position: absolute;
            margin-top: 55px;
            left: 0;
            background-color: $color2;
            border-radius: 7px;
            background: linear-gradient($color2, darken($color2, 10%));

            &:after {
                content: "";
                position: absolute;
                margin-top: 5px;
                margin-right: 7px;
                width: 17px;
                height: 60px;
                border-radius: 3px;
                background-color: transparent;
                opacity: 0.8;
                background: linear-gradient(to bottom,
                        $color2,
                        $color2 50%,
                        lighten($color2, 15%) 50%,
                        lighten($color2, 15%));
                background-size: 100% 20px;
                animation: helix-movement 110ms linear infinite;

            }
        }


        .hat {
            width: 65px;
            height: 25px;
            position: absolute;
            margin-top: 26px;
            left: 70px;
            background-color: $color2;
            border-radius: 10px 10px 0 0;
            background: linear-gradient($color2, darken($color2, 3%));
            direction: ltr;

            .periscope {
                position: absolute;
                width: 7px;
                height: 20px;
                background-color: $color2;
                margin-top: -27px;
                margin-left: 32px;
                border-radius: 5px 5px 0 0;

                &:after,
                &:before {
                    content: "";
                    position: absolute;
                    width: 15px;
                    height: 7px;
                    border-radius: 5px;
                    background-color: $color2;
                }
            }

            .leds-wrapper {
                width: 53px;
                height: 13px;
                position: relative;
                top: 7px;
                left: 7px;
                background-color: $color2;
                border-radius: 10px;
                background: linear-gradient(darken($color2, 12%), darken($color2, 16%));

                .leds {
                    position: absolute;
                    margin-top: 4px;
                    margin-left: 7px;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background-color: white;
                    animation: leds-off 500ms linear infinite;

                    &:after,
                    &:before {
                        content: "";
                        position: absolute;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background-color: white;
                    }

                    &:after {
                        margin-top: 0px;
                        margin-left: 17px;
                    }

                    &:before {
                        margin-top: 0px;
                        margin-left: 34px;
                    }
                }
            }
        }


    }
}


@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes leds-off {
    100% {
        opacity: 0.3;
    }
}

@keyframes helix-movement {
    100% {
        background: linear-gradient(to bottom,
                lighten($color2, 15%) 50%,
                lighten($color2, 15%),
                $color2,
                $color2 50%);
        background-size: 100% 20px;
    }
}


@keyframes diving {
    0% {
        margin-top: 5px;
    }

    50% {
        margin-top: 15px;
    }

    100% {
        margin-top: 5px;
    }
}

@keyframes diving-rotate {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(3deg);
    }

    75% {
        transform: rotate(-2deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes bubble1-h-movement {
    0% {
        margin-left: 80%;
    }

    100% {
        margin-left: -100%;
    }
}

@keyframes bubble2-h-movement {
    0% {
        margin-left: 65%;
    }

    100% {
        margin-left: -5%;
    }
}

@keyframes bubble1-v-movement {
    0% {
        margin-top: 115px;
    }

    100% {
        margin-top: 160px;
    }
}

@keyframes bubble2-v-movement {
    0% {
        margin-top: 115px;
    }

    100% {
        margin-top: 90px;
    }
}

@keyframes bubble-scale-movement {
    0% {
        transform: scale(1.4);
    }

    100% {
        transform: scale(0.9);
    }
}

@keyframes light-movement {
    0% {
        transform: rotate(-40deg);
    }

    50% {
        transform: rotate(-70deg);
    }

    100% {
        transform: rotate(-40deg);
    }
}