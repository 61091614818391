.Fruit-watermelon {
    position: relative;
    width: 0;
    border: 2.2em solid #f33;
    border-radius: 100% 0 0 0;
    box-shadow: -0.25em -0.25em 0 0.25em #373;
    transform: rotate(-135deg);
}

.Fruit-watermelon:before {
    content: "";
    position: absolute;
    bottom: 0.2em;
    right: 0.2em;
    width: 0.33em;
    height: 0.33em;
    background-color: #fff;
    border-radius: 0 50%;
    box-shadow: 1.75em 0 0 0 #fff, 0 1.75em 0 0 #fff, 2.5em -1.5em 0 0 #fff,
        -1.5em 2.5em 0 0 #fff, 2em 2em 0 0 #fff;
}

.Fruit-watermelon:after {
    content: "";
    position: absolute;
    top: -2.6em;
    left: -2.6em;
    width: 4.8em;
    height: 4.8em;
    box-shadow: inset -0.35em -0.35em rgba(0, 0, 0, 0.2);
}