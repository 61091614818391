
.bird {
	//background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/174479/bird-cells-new.svg);
	background-image: url(./flying-bird.gif);
	background-size: auto 100%;
	background-repeat: no-repeat;
	width: 150px;
	height: 150px;
	will-change: background-position;

	animation-name: fly-cycle;
	animation-timing-function: steps(10);
	animation-iteration-count: infinite;

	&--one {
		animation-duration: 1s;
		animation-delay: -0.5s;
	}

	&--two {
		animation-duration: 0.3s;
		animation-delay: -0.75s;
	}

	&--three {
		animation-duration: 1.25s;
		animation-delay: -0.25s;
	}

	&--four {
		animation-duration: 1.1s;
		animation-delay: -0.5s;
	}
	&--five {
		animation-duration: 1.5s;
		animation-delay: -0.6s;
	}

}

.bird-container {
	position: absolute;
	top: 20%;
	right: -10%;
	transform: scale(0) translateX(0vw);
	will-change: transform;

	animation-name: fly-right-one;
	animation-timing-function: linear;
	animation-iteration-count: infinite;

	&--one {
		top: 23%;
		animation-duration: 12s;
		animation-delay: 0;
	}

	&--two {
		top: 33%;
		animation-duration: 10s;
		animation-delay: 1s;
	}

	&--three {
		top: 13%;
		animation-duration: 14.6s;
		animation-delay: 9.5s;
	}

	&--four {
		top: 15%;
		animation-duration: 16s;
		animation-delay: 10.25s;
	}
	&--five {
		top: 10%;
		animation-duration: 14s;
		animation-delay: 14.25s;
	}

}

@keyframes fly-cycle {

	100% {
	//	background-position: -900px 0;
	}

}

@keyframes fly-right-one {

	0% {
		transform: scale(0.3) translateX(0vw);
	}

	10% {
		transform: translateY(2vh) translateX(-10vw) scale(0.4);
	}

	20% {
		transform: translateY(0vh) translateX(-30vw) scale(0.6);
	}

	30% {
		transform: translateY(4vh) translateX(-50vw) scale(0.9);
	}

	40% {
		transform: translateY(2vh) translateX(-70vw) scale(0.8);
	}

	50% {
		transform: translateY(0vh) translateX(-90vw) scale(1);
	}

	60% {
		transform: translateY(0vh) translateX(-110vw) scale(0.3);
	}

	100% {
		transform: translateY(0vh) translateX(-110vw) scale(0.6);
	}

}

$bg: #a0d4ff;
$d-grey: #191919;
$white: #ecf0f1;
$d-white: #bec3c7;
$red: #e84c3d;
$d-red: #c1392d;
$beige: #f2c854;
$d-beige: #dba925;
$brown: #bc6c49;
$d-brown: #874325;

/* mixins */
@mixin pseudo ($position: absolute, $display: block, $content: ''){
	position: $position;
	display: $display;
	content: '';
}
@mixin size($height, $width){
	height: $height;
	width: $width;
}
@mixin cross($property, $val){
	-webkit-#{$property}: $val;
	-moz-#{$property}: $val;
	-ms-#{$property}: $val;
	#{$property}: $val;
}

.ballon{
	position: absolute;
	top: 3%;
	margin: auto;
	@include size(500px,500px);

	transform: scale(0) translateX(0vw);
	animation-name: fly-right-one;
	animation-timing-function: steps(5000);
	animation-iteration-count: infinite;
	animation-duration: 120s;
	animation-delay: -0.1s;
}
.stripes{
	position: absolute;
	@include size(250px,250px);
	left: calc((100% - 250px)/2);
	background: $red;
	border-radius: 48% 48% 50% 50%;
	box-shadow: inset 10px -5px 1px $d-red;
	z-index: 2;
	&::before{
		@include pseudo;
		@include size(100%, 70%);
		background: $white;
		border-radius: 50%;
		left: calc(30%/2);
		box-shadow: inset 10px -5px 1px $d-white, 2px 0px 1px $d-red;
	}&::after{
		@include pseudo;
		@include size(100%, 30%);
		background: $red;
		border-radius: 50% 50% 35% 35%;
		left: calc(70%/2);
		box-shadow: inset 5px -5px 1px $d-red, 3px 0px 1px $d-white;
	}
}
.rope-top{
	position: absolute;
	@include size(235px,210px);
	left: calc((100% - 210px)/2);
	border-radius: 50%;
	border-top: 0px solid $d-beige;;
	border-right: 4px solid $d-beige;
	border-left: 4px solid $d-beige;
	border-bottom: 40px solid transparent;
	z-index: 2;
	&::before{
		@include pseudo;
		@include size(100%, 100%);
		left: calc(((100% - 210px)/2) + 3px);
		border-radius: 50%;
		border-top: 0px solid $beige;
		border-right: 4px solid $beige;
		border-left: 4px solid $beige;
		border-bottom: 40px solid transparent;
	}
}
.rope-middle{
	position: absolute;
	@include size(50px,275px);
	left: calc((100% - 275px)/2);
	top: 22%;
	border-radius: 50%;
	border-bottom: 8px solid $d-beige;
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
	z-index: 3;
	&::after{
		@include pseudo;
		@include size(100%, 100%);
		left: calc((100% - 275px)/2);
		top: -5%;
		border-radius: 50%;
		border-bottom: 8px solid $beige;
		border-right: 5px solid transparent;
		border-left: 5px solid transparent;
}
}
%knot{
	position: absolute;
	@include size(20px,10px);
	border-radius: 50%;
	box-shadow: inset 3px -3px 1px $d-beige, 1px 0.1px 2px $d-beige;
	top: 70%;
	background: $beige;
	@include cross(transform, rotate(-45deg));
	z-index: 1;
	&::after{
		@include pseudo;
		@include size(100%,100%);
		background: $beige;
		border-radius: 50%;
		@include cross(transform, rotate(90deg));
		box-shadow: inset -3px -3px 1px $d-beige, 1px 0.1px 2px $d-beige;
}}
.knotR{
	@extend %knot;
	left: 12%;
}
.knotL{
	@extend %knot;
	left: 85%;
}
.knotT{
	@extend %knot;
	@include size(10px,2px);
	left: calc((100% - 5px)/2);
	top: -275%;
	@include cross(transform, rotate(-60deg));
	box-shadow: inset 0.3px -0.3px 1px $d-beige, 0.1px 0.1px 2px $d-beige;
	&::after{
		@include cross(transform, rotate(120deg));
		box-shadow: inset -0.3px -0.3px 1px $d-beige, 0.1px 0.1px 2px $d-beige;
}}
.rope-bottom-front{
	position: absolute;
	@include size(200px,6px);
	left: 38%;
	top: 28%;
	background: $beige;
	box-shadow: inset 2px -2px 1px $d-beige;
	@include cross(transform, rotate(-25deg));
	z-index: 2;
	&::after{
		@include pseudo;
		@include size(100%,100%);
		background: $beige;
		box-shadow: inset 2px -2px 1px $d-beige;
		left: 105px;
		top: 25%;
		@include cross(transform, rotate(50deg));
}}
.rope-bottom-back{
	position: absolute;
	@include size(180px,6px);
	left: 40.5%;
	top: 32%;
	background: $d-beige;
	@include cross(transform, rotate(-25deg));
	z-index: 1;
	&::after{
		@include pseudo;
		@include size(100%,100%);
		background: $d-beige;
		left: 73px;
		top: 25%;
		@include cross(transform, rotate(50deg));
}}
.basket{
	position: absolute;
	@include size(50px,50px);
	left: calc((100% - 50px)/2);
	top: 65%;
	background: $brown;
	border-radius: 20px 20px 40% 40%;
	box-shadow: inset 8px -8px 1px $d-brown;
	z-index: 2;
	&::after{
		@include pseudo;
		@include size(20px,60px);
		left: calc((100% - 60px)/2);
		background: $brown;
		border-radius: 20px 20px 50% 50%;
		box-shadow: inset 8px -5px 1px $d-brown, 0px 1px 2px $d-grey;}}