:root {
	--sky: #124380;
	--sea: #002a6b;
	--ground: #00112c;
	--road: #000b1c;
	--light-blue: #00366d;
	--door-border: #0d2f59;
	--black: #161a1f;
	--light: #f8cc4c;
	--dark-blue: #021033;
}

.BeaconInLightHouse {
	margin: 0;
	height: 100vh;
	background: linear-gradient(to bottom,  var(--sky) 0%, var(--sky) 73%, var(--sea) 73%, var(--sea) 80%, var(--ground) 80%, var(--ground) 100%);
	position: relative;
	display: flex;
	justify-content: center;
	align-items: flex-end;
}
.BeaconInLightHouse-lighthouse {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	height: 75vh;
}
.BeaconInLightHouse-lighthouse .BeaconInLightHouse-road{
	position: absolute;
	bottom: 0;
	background: var(--road);
	width: 70vw;
	height: 20vh;
	clip-path: polygon(30% 0, 70% 0%, 100% 100%, 0 100%)
}
.BeaconInLightHouse-door {
	background: var(--ground);
	height: 8vh;
	width: 5vh;
	position: absolute;
	bottom: 22vh;
	z-index: 2;
	box-shadow:
		0 0 0 15px var(--door-border),
		50px 0 0 15px var(--light-blue),
		-50px 0 0 15px var(--light-blue),
		0 -51vh 0 -6px var(--ground);
}
.BeaconInLightHouse-stories {
	background: var(--light-blue);
	width: 12vh;
	height: 40vh;
	position: absolute;
	bottom: 30vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
.BeaconInLightHouse-stories:before, .BeaconInLightHouse-last-story:before, .BeaconInLightHouse-light-room:after {
	position: absolute;
	content: '';
	top: -10px;
	width: 12vh;
	height: 3vh;
	background: var(--light-blue);
	border-radius: 100%;
	z-index: 2;
}
.BeaconInLightHouse-stories:before{
	height: 6vh;
}
.BeaconInLightHouse-stories:after, .BeaconInLightHouse-top:after  {
	position: absolute;
	content: '';
	top: -18px;
	width: 20vh;
	height: 4.5vh;
	background: var(--ground);
	border-radius: 100%;
	box-shadow: 0 -2px 0 var(--light-blue),
		 0 -4px 0 var(--light-blue),
		 0 -6px 0 var(--light-blue),
		 0 -7px 0 var(--light-blue)
}
.BeaconInLightHouse-window {
	position: absolute;
	bottom: 15vh;
	height: 2vh;
	width: 3vh;
	background: var(--ground);
	box-shadow: 0 -20vh var(--ground);
	z-index: 3;
}
.BeaconInLightHouse-last-story {
	background: var(--light-blue);
	position: absolute;
	width: 12vh;
	height: 10vh;
	bottom: 70vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
.BeaconInLightHouse-top {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	bottom: 80vh
}
.BeaconInLightHouse-top:before {
	content: '';
	position: absolute;
	bottom: 1.7vh;
	width: 15vh;
	height: 2vh;
	border-radius: 100%;
	background: var(--dark-blue);
}
.BeaconInLightHouse-top > .BeaconInLightHouse-light-room {
	content: '';
	position: absolute;
	width: 12vh;
	background: var(--light);
	height: 7vh;
	bottom: 3vh;
}
.BeaconInLightHouse-top > .BeaconInLightHouse-light-room:after {
	background: var(--light);
}
.BeaconInLightHouse-cupola {
	width: 12vh;
	height: 10vh;
	border-radius: 100%;
	position: absolute;
	background: var(--dark-blue);
	bottom: 5vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
.BeaconInLightHouse-lightning-rod {
	position: absolute;
	width: 20px;
	height: 30px;
	border-radius: 100%;
	background: var(--black);
	bottom: 13vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
.BeaconInLightHouse-lightning-rod:after {
	content: '';
	position: absolute;
	width: 2px;
	height: 5vh;
	background: var(--black);
	bottom: 10px;
}

.BeaconInLightHouse-lighthouse:after {
	position: absolute;
	content: '';
	transform-origin: 100% 50%;
	width: 100vw;
	height: 100vh;
	background: var(--light);
	left: -50vw;
	opacity: 1;
	z-index: 4;
	top: -25vh;
	animation: light_rotate 2s linear alternate infinite;
}

.BeaconInLightHouse-lighthouse:before {
	position: absolute;
	content: '';
	width: 100vw;
	height: 100vh;
	top: -25vh;
	left: -50vw;
	background: var(--light);
	z-index: 5;
	opacity: 0.2;
	animation: flash 2s linear alternate infinite;
}

@keyframes light_rotate{
	0% {
		clip-path: polygon(0 5vh, 0 25vh, 0 25vh, 50% 15vh, 50% 10vh, 0% 5vh);
	}
	7% {
		clip-path: polygon(0 0, 0 40vh, 0 40vh, 50% 15vh, 50% 10vh, 0% 0vh);
	}
	15% {
		clip-path: polygon(0 0, 0 60vh, 0 60vh, 50% 15vh, 50% 10vh, 20% 0vh);
	}
	23% {
		clip-path: polygon(0 0, 0 100vh, 0 100vh, 50% 15vh, 50% 10vh, 25% 0vh);
	}
	31% {
		clip-path: polygon(0 0, 0 100vh, 20% 100vh, 50% 15vh, 50% 10vh, 30% 0vh);
	}
	38% {
		clip-path: polygon(0 0, 0 100vh, 40% 100vh, 50% 15vh, 50% 10vh, 40% 0vh);
	}
	45% {
		clip-path: polygon(0 0, 0 100vh, 45% 100vh, 50% 15vh, 50% 10vh, 45% 0vh);
		opacity: 1;
	}
	46% {
		opacity: 0;
	}
	54% {
		opacity: 0;
	}
	55% {
		clip-path: polygon(100% 0, 100% 100vh, 55% 100vh, 50% 15vh, 50% 10vh, 55% 0vh);
		opacity: 1;
	}
	62% {
		clip-path: polygon(100% 0, 100% 100vh, 60% 100vh, 50% 15vh, 50% 10vh, 60% 0vh);
	}
	69% {
		clip-path: polygon(100% 0, 100% 100vh, 80% 100vh, 50% 15vh, 50% 10vh, 70% 0vh);
	}
	76% {
		clip-path: polygon(100% 0, 100% 100vh, 100% 100vh, 50% 15vh, 50% 10vh, 75% 0vh);
	}
	83% {
		clip-path: polygon(100% 0, 100% 60vh, 100% 60vh, 50% 15vh, 50% 10vh, 80% 0vh);
	}
	91% {
		clip-path: polygon(100% 0, 100% 40vh, 100% 40vh, 50% 15vh, 50% 10vh, 100% 0vh);
	}
	100% {
		clip-path: polygon(100% 5vh, 100% 25vh, 100% 25vh, 50% 15vh, 50% 10vh, 100% 5vh);
	}
}

@keyframes flash {
	0%, 45%, 55%, 100% {
		opacity: 0
	}
	46% {
		opacity: 0.5
	}
	50% {
		opacity: 1;
	}
	54% {
		opacity: 0.5;
	}
}