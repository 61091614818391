.Balloon_Balloon {
    height: 125px;
    width: 105px;
    border-radius: 75% 75% 70% 70%;
    position: relative;
    background-color: #531b9bb3;
    box-shadow: inset 10px 10px 10px #06060552;
    -webkit-animation: Balloon_Balloon_balloon3 6s ease-in-out infinite;
    -moz-animation: Balloon_Balloon_balloon3 6s ease-in-out infinite;
    -o-animation: Balloon_Balloon_balloon3 6s ease-in-out infinite;
    animation: Balloon_Balloon_balloon3 6s ease-in-out infinite;
}

@keyframes Balloon_Balloon_balloon3 {

    0%,
    100% {
        transform: translate(0, -10px) rotate(6eg);
    }

    50% {
        transform: translate(-20px, -30px) rotate(-8deg);
    }
}

.Balloon_Balloon:before {
    content: "";
    height: 35px;
    width: 1px;
    padding: 1px;
    background-color: #000;
    display: block;
    position: absolute;
    top: 125px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 75% 75% 70% 70%;
    z-index: -2;
}

.Balloon_Balloon:after {
    content: "▲";
    color: #531b9bb3;
    text-align: center;
    display: block;
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: -1;
}