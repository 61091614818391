.Bat_wrap{
  position: relative;
  animation: FlyingBee_dodging1 7s ease-in-out infinite;
}
.Bat_bat {
    position: relative;
    transform-origin: center;
    z-index: 3;
    direction: ltr;
    transform: scale(0.6);
    display: flex;
    width: 350px;
  }

  .Bat_body {
    position: relative;
    width: 50px;
  }

  .Bat_wing {
    width: 150px;
    position: relative;
    transform-origin: right center;
  }

  .Bat_leftwing {
    left: 20px;
    animation: 0.8s flapLeft infinite ease-in-out;
  }

  .Bat_rightwing {
    left: -170px;
    transform: scaleX(-1);
    animation: 0.8s flapRight infinite ease-in-out;
  }

  .Bat_eyes {
    position: absolute;
    height:15px;
    width:15px;
    right:calc(50% );
    background-color:#eee;
    box-shadow:  20px 0 0 #eee;
    border-radius:100%;
    z-index: 3;
    top: 10px;
  }
  .Bat_pupil {
    position:absolute;
    height:4px;
    width:4px;
    top:7px;
    left:7px;
    background-color:#000;
    border-radius:100%;
    box-shadow:  20px 0 0 #000;
    animation: 2.8s Bat_eyesMove  infinite ease-in-out;
  }
  @keyframes Bat_Move {
    0% {  top: 10px;}
    50% {  top: 100px;}
    100% { top: 10px; }
  }

  @keyframes Bat_eyesMove {
    0% { transform: rotateZ(0); top: 10px;}
    50% { transform: rotateZ(5deg) rotateY(20deg); top: 1px;}
    100% { transform: rotateZ(0);top: 10px; }
  }

@keyframes flapLeft {
    0% { transform: rotateZ(0); }
    50% { transform: rotateZ(10deg) rotateY(40deg); }
    100% { transform: rotateZ(0); }
  }

  @keyframes flapRight {
    0% { transform: scaleX(-1) rotateZ(0); }
    50% { transform: scaleX(-1) rotateZ(10deg) rotateY(40deg); }
    100% { transform: scaleX(-1) rotateZ(0); }
  }