.custom-fish-fish {
    position: relative;
    left: 0px;
    height: 85px;
    width: 120px;
    transform: scaleX(-1) rotate(15deg);
}

.custom-fish-fish-body {
    position: absolute;
    width: 115px;
    height: 75px;
    border-radius: 50%;
    background-color: orange;
    box-shadow: 0px -7px 7px inset #00000045;
    -webkit-box-shadow: 0px -7px 7px inset #00000045;
    -moz-box-shadow: 0px -7px 7px inset #00000045;
    -o-box-shadow: 0px -7px 7px inset #00000045;
    -ms-box-shadow: 0px -7px 7px inset #00000045;
    transform: skewX(5deg) skewY(-10deg);
    -webkit-transform: skewX(5deg) skewY(-10deg);
    -moz-transform: skewX(5deg) skewY(-10deg);
    -ms-transform: skewX(5deg) skewY(-10deg);
    -o-transform: skewX(5deg) skewY(-10deg);
}

.custom-fish-fish-body:before {
    content: "";
    width: 15px;
    height: 15px;
    background-color: #ffffff;
    position: absolute;
    border-radius: 50%;
    right: 18px;
    top: 16px;
}

.custom-fish-fish-body:after {
    content: "";
    width: 7px;
    height: 9px;
    background-color: #000000;
    position: absolute;
    border-radius: 50%;
    right: 20px;
    top: 18px;
    animation: custom-fish-eye-move 2s ease 0s infinite normal;
}

@keyframes custom-fish-eye-move {
    0% {
        right: 20px;
        top: 18px;
    }

    25% {
        right: 23px;
        top: 18px;
    }

    50% {
        right: 20px;
        top: 20px;
    }

    100% {
        right: 20px;
        top: 18px;
    }
}

.custom-fish-top-fin {
    position: absolute;
    left: 35px;
    top: -23px;
    width: 28px;
    height: 50px;
    border-radius: 20% 50%;
    background-color: orange;
    box-shadow: 2px -11px 7px inset #00000080;
    -webkit-box-shadow: 2px -11px 7px inset #00000080;
    -moz-box-shadow: 2px -11px 7px inset #00000080;
    -o-box-shadow: 2px -11px 7px inset #00000080;
    -ms-box-shadow: 2px -11px 7px inset #00000080;
    transform: rotate(80deg) skewX(-23deg);
    -webkit-transform: rotate(80deg) skewX(-23deg);
    -moz-transform: rotate(80deg) skewX(-23deg);
    -ms-transform: rotate(80deg) skewX(-23deg);
    -o-transform: rotate(80deg) skewX(-23deg);
}

@keyframes custom-fish-tail-fin {
    0% {
        transform: rotate(25deg) skewX(-18deg) skewY(-5deg);
        -webkit-transform: rotate(25deg) skewX(-18deg) skewY(-5deg);
        -moz-transform: rotate(25deg) skewX(-18deg) skewY(-5deg);
        -ms-transform: rotate(25deg) skewX(-18deg) skewY(-5deg);
        -o-transform: rotate(25deg) skewX(-18deg) skewY(-5deg);
        left: -35px;
    }

    50% {
        transform: rotate(25deg) skewX(-18deg) skewY(-15deg);
        -webkit-transform: rotate(25deg) skewX(-18deg) skewY(-15deg);
        -moz-transform: rotate(25deg) skewX(-18deg) skewY(-15deg);
        -ms-transform: rotate(25deg) skewX(-18deg) skewY(-15deg);
        -o-transform: rotate(25deg) skewX(-18deg) skewY(-15deg);
        left: -33px;
    }

    100% {
        transform: rotate(25deg) skewX(-18deg) skewY(-5deg);
        -webkit-transform: rotate(25deg) skewX(-18deg) skewY(-5deg);
        -moz-transform: rotate(25deg) skewX(-18deg) skewY(-5deg);
        -ms-transform: rotate(25deg) skewX(-18deg) skewY(-5deg);
        -o-transform: rotate(25deg) skewX(-18deg) skewY(-5deg);
        left: -35px;
    }
}

.custom-fish-tail-fin {
    position: absolute;
    left: -30px;
    width: 43px;
    height: 50px;
    bottom: 0px;
    border-radius: 40% 50%;
    background-color: orange;
    box-shadow: 1px -13px 7px inset #00000080;
    -webkit-box-shadow: 1px -13px 7px inset #00000080;
    -moz-box-shadow: 1px -13px 7px inset #00000080;
    -o-box-shadow: 1px -13px 7px inset #00000080;
    -ms-box-shadow: 1px -13px 7px inset #00000080;
    transform: rotate(25deg) skewX(-18deg) skewY(-15deg);
    -webkit-transform: rotate(25deg) skewX(-18deg) skewY(-15deg);
    -moz-transform: rotate(25deg) skewX(-18deg) skewY(-15deg);
    -ms-transform: rotate(25deg) skewX(-18deg) skewY(-15deg);
    -o-transform: rotate(25deg) skewX(-18deg) skewY(-15deg);
    animation: custom-fish-tail-fin 1s ease 0s infinite normal;
}

.custom-fish-tail-fin2 {
    content: "";
    z-index: 9;
    position: absolute;
    left: -16px;
    bottom: 19px;
    width: 40px;
    height: 48px;
    border-radius: 40% 50%;
    background-color: orange;
    box-shadow: 1px -13px 7px inset #00000080;
    -webkit-box-shadow: 1px -13px 7px inset #00000080;
    -moz-box-shadow: 1px -13px 7px inset #00000080;
    -o-box-shadow: 1px -13px 7px inset #00000080;
    -ms-box-shadow: 1px -13px 7px inset #00000080;
    transform: rotate(85deg);
    -webkit-transform: rotate(85deg);
    -moz-transform: rotate(85deg);
    -ms-transform: rotate(85deg);
    -o-transform: rotate(85deg);
}

.custom-fish-mouth {
    width: 15px;
    border-bottom: 2px solid #fff;
    background: #fff;
    border-radius: 0 20px 20px 20px;
    position: absolute;
    top: 38px;
    height: 3px;
    animation: custom-fish-mouth 2s ease 0s infinite normal;
}

@keyframes custom-fish-mouth {
    0% {
        height: 3px;
    }

    50% {
        height: 5px;
    }

    100% {
        height: 3px;
    }
}


@keyframes custom-fish-side-fin {
    0% {
        transform: rotate(15deg) skewX(-18deg) skewY(-15deg);
        -webkit-transform: rotate(15deg) skewX(-18deg) skewY(-15deg);
        -moz-transform: rotate(15deg) skewX(-18deg) skewY(-15deg);
        -ms-transform: rotate(15deg) skewX(-18deg) skewY(-15deg);
        -o-transform: rotate(15deg) skewX(-18deg) skewY(-15deg);
    }

    50% {
        transform: rotate(25deg) skewX(-18deg) skewY(-15deg);
        -webkit-transform: rotate(25deg) skewX(-18deg) skewY(-15deg);
        -moz-transform: rotate(25deg) skewX(-18deg) skewY(-15deg);
        -ms-transform: rotate(25deg) skewX(-18deg) skewY(-15deg);
        -o-transform: rotate(25deg) skewX(-18deg) skewY(-15deg);
    }

    100% {
        transform: rotate(15deg) skewX(-18deg) skewY(-15deg);
        -webkit-transform: rotate(15deg) skewX(-18deg) skewY(-15deg);
        -moz-transform: rotate(15deg) skewX(-18deg) skewY(-15deg);
        -ms-transform: rotate(15deg) skewX(-18deg) skewY(-15deg);
        -o-transform: rotate(15deg) skewX(-18deg) skewY(-15deg);
    }
}

.custom-fish-side-fin {
    position: absolute;
    left: 39px;
    bottom: 0;
    width: 33px;
    height: 38px;
    border-radius: 50% 40%;
    background-color: orange;
    box-shadow: 1px -13px 7px inset #00000080;
    -webkit-box-shadow: 1px -13px 7px inset #00000080;
    -moz-box-shadow: 1px -13px 7px inset #00000080;
    -o-box-shadow: 1px -13px 7px inset #00000080;
    -ms-box-shadow: 1px -13px 7px inset #00000080;
    transform: rotate(25deg) skewX(-18deg) skewY(-15deg);
    -webkit-transform: rotate(25deg) skewX(-18deg) skewY(-15deg);
    -moz-transform: rotate(25deg) skewX(-18deg) skewY(-15deg);
    -ms-transform: rotate(25deg) skewX(-18deg) skewY(-15deg);
    -o-transform: rotate(25deg) skewX(-18deg) skewY(-15deg);
    animation: custom-fish-side-fin 2s ease 0s infinite normal;
}

.custom-fish-scale {
    position: absolute;
    width: 21px;
    height: 24px;
    border-radius: 39%;
    background-color: orange;
    box-shadow: 3px -3px 5px inset #00000020;
    -webkit-box-shadow: 3px -3px 5px inset #00000020;
    -moz-box-shadow: 3px -3px 5px inset #00000020;
    -o-box-shadow: 3px -3px 5px inset #00000020;
    -ms-box-shadow: 3px -3px 5px inset #00000020;
    transform: rotate(22deg) skewX(-3deg) skewY(-10deg);
    -webkit-transform: rotate(22deg) skewX(-3deg) skewY(-10deg);
    -moz-transform: rotate(22deg) skewX(-3deg) skewY(-10deg);
    -ms-transform: rotate(22deg) skewX(-3deg) skewY(-10deg);
    -o-transform: rotate(22deg) skewX(-3deg) skewY(-10deg);
}

.custom-fish-scale-1 {
    left: 20px;
    bottom: 40px;
}

.custom-fish-scale-2 {
    left: 36px;
    bottom: 50px;
}

.custom-fish-scale-3 {
    left: 53px;
    bottom: 45px;
}