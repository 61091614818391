.Fruit-peach {
    width: 6em;
    height: 5em;
    background-color: #f87a95;
    box-shadow: inset 0.2em -0.2em #cd6179;
    border-radius: 2.5em 2.5em 3em 3em;
}

.Fruit-peach:before {
    content: "";
    position: absolute;
    top: -1.7em;
    left: 3em;
    height: 0;
    width: 0;
    border: 1em solid #539e3d;
    border-radius: 50% 0 50% 0;
    border-bottom-color: #437512;
    border-right-color: #437512;
}