.Fruit-blueberry {
    width: 6em;
    height: 5.5em;
    border-radius: 50%;
    background-color: #89a2ca;
    box-shadow: inset 0.2em -0.2em #6081b8;
}

.Fruit-blueberry:before {
    content: "";
    width: 2em;
    height: 1em;
    left: 2em;
    position: absolute;
    background-color: #3e4d64;
    border-radius: 50%;
}