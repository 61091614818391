.Fruit-cherry {
    width: 3em;
    height: 2.5em;
    background-color: #c20e2b;
    box-shadow: inset 0.2em -0.2em #9b0b22;
    border-radius: 2.5em 2.5em 4em 4em;
    margin-left: 0.2em;
    transform: rotate(357deg);
}

.Fruit-cherry:nth-child(2) {
    transform: rotate(11deg);
}

.Fruit-cherry1 {
    top: 3em;
    left: 0.2em;
    transform: rotate(15deg);
}

.Fruit-cherry2 {
    top: 3em;
    left: 3.4em;
    transform: rotate(-15deg);
}

.Fruit-cherry1:before {
    content: "";
    width: 1em;
    height: 2.8em;
    background-color: transparent;
    box-shadow: 0.2em -0.1em 0 0 #539e3d;
    border-radius: 50%;
    position: absolute;
    top: -2.6em;
    left: 1em;
    transform: rotate(19deg);
}

.Fruit-cherry2:before {
    content: "";
    width: 1em;
    height: 2.8em;
    background-color: transparent;
    box-shadow: 0.2em -0.1em 0 0 #539e3d;
    border-radius: 50%;
    position: absolute;
    top: -2.5em;
    left: 0.5em;
    transform: rotate(-12deg);
}

.Fruit-cherry-join {
    width: 0.7em;
    height: 0.4em;
    top: -2.4em;
    right: 2.3em;
    position: absolute;
    background-color: #724126;
    border-radius: 20%;
    transform: rotate(-12deg);
    z-index: 999;
}